import styled from "styled-components";

export const StyledGalleryGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
`;

export const StyledGalleryGridItem = styled.div`
  display: flex;
  border-radius: 10px;
  overflow: hidden;
`;
