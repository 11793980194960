import React, { useState } from "react";

import "react-toastify/dist/ReactToastify.min.css";
import { NotificationContainer } from "@yamaha-admin-sb/notification";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeContext } from "@/contexts/ThemeContext";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme/theme";
import { ModalContext } from "@/contexts/ModalContext";
import "./assets/css/styles.css";
import Login from "./pages/login";
import Lead from "./pages/lead";
import Form from "./pages/form";
import Main from "./pages";

const App: React.FC = (): JSX.Element => {
  const [modalValue, setModalValue] = useState({
    payload: null,
    modalContractConfirm: false,
  });

  const [themeValue, setThemeValue] = useState<"light" | "dark">("light");
  const thisTheme = theme(themeValue);

  return (
    <ModalContext.Provider value={[modalValue, setModalValue]}>
      <ThemeContext.Provider value={[themeValue, setThemeValue]}>
        <ThemeProvider theme={thisTheme}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/form" element={<Form />} />
              <Route path="/:uuid" element={<Lead />} />
              <Route path="/:uuid/login" element={<Login />} />
            </Routes>
          </BrowserRouter>
          <NotificationContainer
            position="bottom-right"
            closeButton={false}
            hideProgressBar={true}
            newestOnTop={true}
            draggable={false}
          />
        </ThemeProvider>
      </ThemeContext.Provider>
    </ModalContext.Provider>
  );
};

export default App;
