import styled, { css } from "styled-components";

export const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledFlexRowItem = styled.div<{ col: number }>`
  width: ${({ col }) => `calc(100% / 12 * ${col})`};
`;
