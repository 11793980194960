import React from "react";
import { Title } from "@yamaha-admin-sb/title";
import {
  WrappedLeadTitle,
  WrappedBlock,
  StyledInfoItem,
  StyledNameItem,
  StyledValueItem,
} from "../leads.styled";
import { Avatar } from "@yamaha-admin-sb/avatar";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { BaseUserOutput } from "@/graphql/generated";
import styled from "styled-components";

interface ISelectedEmployee {
  role: string;
  person?: BaseUserOutput;
}

const WrappedAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SelectedEmployee: React.FC<ISelectedEmployee> = (props) => {
  const { role, person } = props;
  return (
    <WrappedBlock>
      <WrappedLeadTitle className="space-24">
        <Title level={4} weight={500} color="gray-700">
          {role}
        </Title>
      </WrappedLeadTitle>
      {person?.id ? (
        <WrappedAvatar className="space-12">
          <Avatar
            size="xs"
            isDisabled={true}
            firstName={person?.name}
            lastName={person?.surname}
          />
          <Paragraph
            size={12}
            weight={500}
          >{`${person?.name} ${person?.surname}`}</Paragraph>
        </WrappedAvatar>
      ) : (
        <Paragraph size={12} color="gray-500">
          Не выбран
        </Paragraph>
      )}
      {person && person.phone ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Телефон
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <a href={`tel:${person.phone}`}>
              <Paragraph size={12} weight={400} color="blue-200">
                {person?.phone}
              </Paragraph>
            </a>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
    </WrappedBlock>
  );
};
