import {
  BaseUserOutput,
  GetLeadPageQuery,
  TechnicOutput
} from "@/graphql/generated";
import { colors } from "@/helpers/index";
import React from "react";
import styled, { css } from "styled-components";
import { RequestBlock } from "./summary/RequestBlock";
import { SelectedEmployee } from "./summary/SelectedEmployee";
import { TechnicsBlock } from "./summary/TechnicsBlock";

interface IMainSummary {
  data: GetLeadPageQuery;
}
const StyledReqInfo = styled.div<{ isDisabled?: boolean }>`
  flex: 0 0 376px;
  min-width: 300px;
  border-left: solid 1px ${colors.gray200};
  padding-left: 24px;
  position: relative;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        z-index: 10;
        opacity: 0.7;
      }
    `}
`;
export const MainSummary: React.FC<IMainSummary> = (props) => {
  const { data } = props;

  const isOffsiteDiagnostics =
    data?.getLeadPage.lService?.slug === "offsite-diagnostics";

  return (
    <StyledReqInfo>
      <RequestBlock isOffsiteDiagnostics={isOffsiteDiagnostics} lead={data} />
      {data.getLeadPage.technics?.length ? (
        data.getLeadPage.technics
          .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
          .map((item, index) => (
            <TechnicsBlock
              key={index}
              index={index}
              params={item as TechnicOutput}
            />
          ))
      ) : (
        <></>
      )}
      <SelectedEmployee
        role="Менеджер"
        person={data.getLeadPage.manager as BaseUserOutput}
      />
      <SelectedEmployee
        role="Специалист"
        person={data?.getLeadPage.specialist as BaseUserOutput}
      />
    </StyledReqInfo>
  );
};
