import React, { useState } from "react";
import styled from "styled-components";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { Button } from "@yamaha-admin-sb/button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { useNavigate, useParams } from "react-router-dom";
import { useGetLead } from "@/hooks/useGetLead";
import { useForm } from "react-hook-form";
import { Controller } from "../Controller";
import { inputValidationRule } from "@/helpers/const";

const StyledLoginForm = styled.div`
  width: 100%;
  max-width: 300px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledFormTitle = styled.div`
  margin-bottom: 48px;
  text-align: center;
`;

const StyledFormGroup = styled.div`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:last-child {
    margin-bottom: 8px;
  }
`;

const StyledAction = styled.div`
  margin-top: 32px;
`;

export const LoginForm: React.FC = (): JSX.Element => {
  const { uuid = "" } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ mode: "onBlur" });

  const { isLoading, onGetLeadPage } = useGetLead({
    onSuccess: () => navigate(`/${uuid}`),
  });

  return (
    <StyledLoginForm>
      <StyledFormTitle>
        <Paragraph size={16} weight={500} color="black">
          Войдите в свой аккаунт
        </Paragraph>
      </StyledFormTitle>
      <form
        onSubmit={handleSubmit(() =>
          onGetLeadPage({ access: password, path: uuid })
        )}
      >
        <StyledFormGroup>
          <Controller<IInput>
            name="password"
            label="Пароль"
            type="password"
            placeholder="Введите пароль"
            isBordered={true}
            value={password}
            onChange={(value: string) => setPassword(value)}
            control={control}
            error={errors?.password?.message}
            rules={inputValidationRule}
            component={(inputProps: IInput) => <Input {...inputProps} />}
          />
        </StyledFormGroup>
        <StyledAction>
          <Button
            isDisabled={!password}
            isLoading={isLoading}
            isBlock={true}
            type="submit"
          >
            Войти
          </Button>
        </StyledAction>
      </form>
    </StyledLoginForm>
  );
};
