import { Logo } from "@/components/Logo";
import { FC } from "react";
import styled from "styled-components";
import { mediaRevers } from "../helpers";

interface IAuth {
  children: JSX.Element | JSX.Element[];
}

const StyledAuth = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, 1fr));

  ${mediaRevers.laptop} {
    grid-template-columns: 1fr;
  }
`;

const StyledLogo = styled.div`
  margin-bottom: 160px;
`;

const StyledAuthLeft = styled.div`
  ${mediaRevers.laptop} {
    display: none;
  }
  background-image: linear-gradient(219.63deg, #01ccff 0%, #0185ff 100%);
`;

const StyledAuthRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 40px;
  position: relative;
  width: 100%;
`;

const Auth: FC<IAuth> = (props): JSX.Element => {
  const { children } = props;

  return (
    <StyledAuth>
      <StyledAuthLeft />

      <StyledAuthRight>
        <StyledLogo>
          <Logo width={272} height={28} />
        </StyledLogo>
        {children}
      </StyledAuthRight>
    </StyledAuth>
  );
};

export default Auth;
