import React from "react";
import styled from "@emotion/styled";
import { colors, media } from "@/helpers/index";
import { Icon } from "@yamaha-admin-sb/icon";
import { AttachmentOutput } from "@/graphql/generated";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

interface IImageGallery {
  gallery: Pick<
    AttachmentOutput,
    "createdAt" | "fileName" | "filePath" | "fileSize" | "fileUrl" | "id"
  >[];
  startIndex: number;
  onClose: () => void;
}

const StyledImageGallery = styled.div`
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.black};

  .image-gallery {
    height: 100%;

    &-content {
      height: 100%;
    }

    &-slide-wrapper {
      top: 50%;
      transform: translateY(-50%);
    }

    &-thumbnails-wrapper {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    &-thumbnail {
      &:hover {
        border: 2px solid ${colors.gray300};
      }

      &.active {
        border: 2px solid ${colors.white};
      }

      width: 56px;
      height: 56px;
    }
  }
`;

const StyledClose = styled.div`
  position: absolute;
  right: 50px;
  top: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  border-radius: 50%;
  z-index: 10000;
  cursor: pointer;

  svg {
    fill: ${colors.white};
  }

  ${media.tablet} {
    width: 48px;
    height: 48px;
  }
`;

const StyledButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  border-radius: 50%;
  z-index: 10000;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    fill: ${colors.white};
  }

  &:first-of-type {
    left: 24px;
    right: auto;
  }

  ${media.tablet} {
    width: 48px;
    height: 48px;
  }
`;

const ImageGallery: React.FC<IImageGallery> = (props) => {
  const { gallery, startIndex, onClose } = props;
  const mappedGallery = gallery.map((item) => ({
    original: item.fileUrl,
    thumbnail: item.fileUrl,
    // renderItem: item.type === MediaTypeEnum.VIDEO ? () => (
    //   <StyledVideo src={item.url} controls={true} className="image-gallery-image"/>
    // ) : undefined,
    // renderThumbInner: item.type === MediaTypeEnum.VIDEO ? () => (
    //   <span className="image-gallery-thumbnail-inner">
    //     <video src={item.url} className="image-gallery-thumbnail-image"/>
    //   </span>
    // ) : undefined,
  }));
  return (
    <StyledImageGallery>
      <StyledClose onClick={onClose}>
        <Icon name="cross-24" color="white" />
      </StyledClose>
      <ReactImageGallery
        startIndex={startIndex}
        showFullscreenButton={false}
        showPlayButton={false}
        lazyLoad={true}
        items={mappedGallery}
        renderLeftNav={(onClick, disabled) => (
          <StyledButton onClick={onClick} disabled={disabled}>
            <Icon name="arrow-left" color="white" />
          </StyledButton>
        )}
        renderRightNav={(onClick, disabled) => (
          <StyledButton onClick={onClick} disabled={disabled}>
            <Icon name="arrow-right" color="white" />
          </StyledButton>
        )}
      />
    </StyledImageGallery>
  );
};

export default ImageGallery;
