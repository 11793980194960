import React from "react";
import { TechnicOutput } from "@/graphql/generated";
import { toFormatPrice } from "@/helpers/utils";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Title } from "@yamaha-admin-sb/title";
import {
  StyledInfoItem,
  StyledNameItem,
  StyledValueItem,
  WrappedLeadTitle,
  WrappedBlock,
} from "../leads.styled";
import { gearboxOptions } from "../TechnicsForm";

interface ITechnicsBlock {
  params: TechnicOutput;
  index: number;
}

export const TechnicsBlock: React.FC<ITechnicsBlock> = (props) => {
  const { params, index } = props;
  return (
    <WrappedBlock>
      <WrappedLeadTitle className="space-24">
        <Title level={4} weight={500} color="gray-700">
          {`Автомобиль №${index + 1}`}
        </Title>
      </WrappedLeadTitle>

      {params?.make ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Марка
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={500}>
              {params.make}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      {params?.model?.length ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Модель
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={500}>
              {params.model}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      {params.body?.length ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Кузов
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={500}>
              {params.body.join(", ")}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      {params.gearbox?.length ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Коробка передач
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={500}>
              {params.gearbox
                .map(
                  (item) =>
                    gearboxOptions.find(
                      (gearboxItem) => gearboxItem.value === item
                    )?.name || ""
                )
                .join(", ")}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      {params.driveUnit?.length ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Привод
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={500}>
              {params.driveUnit.join(", ")}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      {params?.engineVolume ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Объем двигателя
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={500}>
              {`${params.engineVolume} л`}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      {params?.mileage ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Пробег
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={500}>
              {`До ${toFormatPrice(params.mileage)} км`}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      {params?.issueYear ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Год выпуска
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={500}>
              {params?.issueYear}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      {params?.color ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Цвет
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={500}>
              {params.color || ""}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      {params?.minPrice || params?.maxPrice ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Цена
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            {params.minPrice && params.maxPrice ? (
              <Paragraph size={12} color="gray-700" weight={500}>
                {`${toFormatPrice(params.minPrice)} ₽ - ${toFormatPrice(
                  params.maxPrice
                )} ₽`}
              </Paragraph>
            ) : (
              <>
                {params.minPrice ? (
                  <Paragraph size={12} color="gray-700" weight={500}>
                    {`От ${toFormatPrice(params.minPrice)} ₽`}
                  </Paragraph>
                ) : (
                  <></>
                )}
                {params.maxPrice ? (
                  <Paragraph size={12} color="gray-700" weight={500}>
                    {`До ${toFormatPrice(params.maxPrice)} ₽`}
                  </Paragraph>
                ) : (
                  <></>
                )}
              </>
            )}
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}

      {params.parameters ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Ссылка
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <a href={params.parameters} target="_blank">
              <Paragraph size={12} color="blue-200" weight={500}>
                Link
              </Paragraph>
            </a>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}

      {params.equipmentOptions ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Параметры
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph size={12} color="gray-700" weight={500}>
              {params.equipmentOptions}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
    </WrappedBlock>
  );
};
