export const getCapitalized = (order: string): string => {
  return `${order[0].toUpperCase()}${order.slice(1)}`;
};

export const getSuffixedDigit = (digit: number) => {
  return digit > 9 ? digit : `0${digit}`;
};

export const getFormattedDate = (date: Date) => {
  return date
    ? `${date.getFullYear()}-${getSuffixedDigit(
        date.getMonth() + 1,
      )}-${getSuffixedDigit(date.getDate())}`
    : undefined;
};

export const getDottedDate = (date: string) => {
  if (!date) return undefined;

  const dateArr = date.replace("T", "-").split("-");

  return `${+dateArr[1]}.${+dateArr[2]}.${dateArr[0]}`;
};

export const toFormatPrice = (price: number) => {
  if (price % 1 !== 0) {
    const splittedPrice = price.toString().split(".");
    return `${splittedPrice[0].replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}.${
      splittedPrice[1]
    }`;
  } else {
    return price.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1 ");
  }
};

export const downloadURL = async (url: string, name: string) => {
  const toDataURL = (url: string) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  };

  const a = document.createElement("a");
  a.href = await toDataURL(`https://ark.onudu.com/${url}`);
  a.download = name;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
