import { FC } from "react";
import Auth from "@/layouts/auth";
import { LoginForm } from "@/components/auth/LoginForm";

const Login: FC = (): JSX.Element => {
  return (
    <Auth>
      <LoginForm />
    </Auth>
  );
};

export default Login;
