import Cookies from "js-cookie";

export type ApiErrorFieldMessage = {
  field: string;
  message: string;
};

export type ApiError = {
  message: string;
  extensions?: {
    code: string;
    response: {
      error: string;
      message: string | ApiErrorFieldMessage[];
      statusCode: number;
    };
  };
};

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
  const [resource, config] = args;
  const response = await originalFetch(resource, config);

  if (
    !response.ok &&
    (!Cookies.get("accessToken") || response.status !== 401)
  ) {
    return Promise.reject(response);
  } else if (Cookies.get("accessToken") && response.status === 401) {
    Cookies.set("accessToken", "", { expires: -1 });
    Cookies.remove("accessToken");
    window.location.assign("/login");
    return Promise.reject(response);
  }

  return response;
};

export const customFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables
): (() => Promise<TData>) => {
  const endpointUrl: string = process.env.REACT_APP_BASE_URL as string;

  const fetchParams: {
    headers: {
      Authorization?: string;
      "content-type": string;
    };
  } = {
    headers: {
      "content-type": "application/json",
    },
  };

  if (Cookies.get("accessToken"))
    fetchParams.headers.Authorization = Cookies.get("accessToken");

  return async () => {
    const res = await fetch(endpointUrl, {
      method: "POST",
      ...fetchParams,
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    const json = await res.json();

    if (json.errors) {
      let { message } = json.errors[0]?.extensions?.response;
      if (Array.isArray(message))
        message = message[0].message ? message[0].message : message[0];
      if (!message) {
        message = "Ошибка, обратитесь в консоль для ознакомления";
        console.log(JSON.stringify(json));
      }
      throw new Error(message);
    }

    return json.data;
  };
};
