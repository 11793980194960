import React from "react";
import styled from "styled-components";
import { Header } from "@/components/menu/Header";
import { ContactEntity } from "@/graphql/generated";

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  padding-bottom: 64px;
`;

const StyledDefault = styled.div`
  min-height: 100vh;
  display: flex;
`;

interface IDefault {
  contact: Pick<ContactEntity, "name" | "surname">;
  children: JSX.Element | JSX.Element[];
}

const Default: React.FC<IDefault> = (props) => {
  const { contact, children } = props;
  return (
    <StyledDefault>
      <StyledContent>
        <Header contact={contact} />
        {children}
      </StyledContent>
    </StyledDefault>
  );
};

export default Default;
