import {
  GenerateCodeStatusEnum,
  useConfirmContractMutation,
  useSendConfirmationCodeMutation,
} from "@/graphql/generated";
import useAddNotification from "@/hooks/useNotification";
import { Button } from "@yamaha-admin-sb/button";
import { Input } from "@yamaha-admin-sb/input";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Title } from "@yamaha-admin-sb/title";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { DataFilled } from "../DataFilled";
import { StyledFormItem } from "../leads.styled";

interface IAcceptProps {
  phone: string;
  isCompleted: boolean;
  onCompleted: (value: boolean) => void;
  contractNumber: string;
}

const StyledForm = styled.div`
  max-width: 368px;
`;

const COUNTER = 60;

const doubleDigitsFormat = (count: number) => {
  return count < 10 ? "0" + count : count;
};
export const Accept: React.FC<IAcceptProps> = (props) => {
  const { phone, isCompleted, onCompleted, contractNumber } = props;
  const { uuid = "" } = useParams();
  const { mutate: confirmContract, isLoading: isCofirming } =
    useConfirmContractMutation();
  const { mutate: sendCode, isLoading: isCodeSending } =
    useSendConfirmationCodeMutation();
  const [isCodeSend, setIsCodeSend] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const notify = useAddNotification();

  const [code, setCode] = useState("");

  const [counter, setCounter] = useState(COUNTER);
  useEffect(() => {
    if (!isCodeSend) return;
    let interval: NodeJS.Timeout;
    if (counter) {
      interval = setInterval(
        () =>
          setCounter((prev) => {
            if (prev) {
              return prev - 1;
            } else {
              clearInterval(interval);
              return 0;
            }
          }),
        1000
      );
    }
  }, [isCodeSend, trigger]);

  const handleCodeSend = () => {
    sendCode(
      { access: { path: uuid, access: Cookies.get("access") || "" } },
      {
        onSuccess: (resp) => {
          switch (resp.sendConfirmationCode.status) {
            case GenerateCodeStatusEnum.Ok:
              setIsCodeSend(true);
              notify("Код отправлен", "success");
              break;
            case GenerateCodeStatusEnum.RequestTtl:
              setCounter(resp.sendConfirmationCode.ttl);
              setTrigger((prev) => !prev);
              setIsCodeSend(true);
              notify(
                `Повторно код можно отправить через  ${resp.sendConfirmationCode.ttl}с`,
                "warning"
              );
              break;
            case GenerateCodeStatusEnum.Error:
              notify("Произошла ошибка при отправке SMS", "error");
              break;
          }
          if (resp.sendConfirmationCode.status === GenerateCodeStatusEnum.Ok) {
          }
        },
        onError: (error) => notify((error as ErrorEvent)?.message, "error"),
      }
    );
  };

  const handleConfirm = () => {
    confirmContract(
      {
        access: { path: uuid, access: Cookies.get("access") || "" },
        code,
      },
      {
        onSuccess: () => {
          onCompleted(true);
          notify("Договор подписан", "success");
        },
        onError: (error) => notify((error as ErrorEvent)?.message, "error"),
      }
    );
  };

  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Подтверждение
        </Title>
      </div>
      {!isCompleted ? (
        <StyledForm>
          <div className="space-20">
            <Paragraph size={12} color="gray-600">
              Для согласования договора мы отправим вам код на телефон,
              указанный в заявке ({phone})
            </Paragraph>
          </div>
          {isCodeSend ? (
            <>
              <div className="space-24">
                <Input
                  isBordered={true}
                  name="code"
                  label="Код из СМС *"
                  placeholder="xxxx"
                  value={code}
                  onChange={(value) => setCode(value)}
                />
              </div>
              {!code ? (
                <Button
                  isDisabled={!!counter}
                  onClick={() => {
                    setCounter(COUNTER);
                    setTrigger((prev) => !prev);
                    handleCodeSend();
                  }}
                >{`Отправить код повторно ${counter ? " через 00:" + doubleDigitsFormat(counter) : ""
                  }`}</Button>
              ) : (
                <>
                  <Button
                    isLoading={isCofirming}
                    onClick={() => {
                      handleConfirm();
                    }}
                  >
                    Подписать договор
                  </Button>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {!isCodeSend ? (
            <>
              <div className="space-20">
                <Button
                  isLoading={isCodeSending}
                  onClick={() => handleCodeSend()}
                >
                  Отправить СМС-код
                </Button>
              </div>
              <Paragraph size={12} color="gray-600">
                Для заключения договора Вы получите код в виде СМС-сообщения по
                номеру телефона, указанного в заявке {contractNumber}.
              </Paragraph>
            </>
          ) : (
            <></>
          )}
        </StyledForm>
      ) : (
        <DataFilled text="Договор успешно согласован" />
      )}
    </StyledFormItem>
  );
};
