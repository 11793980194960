import styled from "styled-components";

export const StyledTabContent = styled.div`
  padding: 40px;
  background-color: ${({ theme }) => theme.color["neutral-white"]};
  border-radius: 0 0 12px 12px;
  border-top: 1px solid #eceef2;
`;

export const StyledSwitchesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const StyledSwitchContainer = styled.div`
  display: inline-block;
`;

export const StyledTabMargin = styled.div`
  margin-bottom: 128px;
`;
