import React, { useContext, useState } from "react";
import {
  StyledFormItem,
  StyledGridContainer,
  StyledGridElement,
} from "@/components/lead/leads.styled";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Controller } from "@/components/Controller";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { useForm } from "react-hook-form";
import {
  emailValidationRule,
  inputValidationRule,
  phoneValidationRule,
  requiredValidationRule,
} from "@/helpers/const";
import { Radio } from "@yamaha-admin-sb/radio";
import styled from "styled-components";

import {
  GenderEnum,
  GetLeadPageQuery,
  UpdateContactPublicPageInput,
} from "@/graphql/generated";
import { Button } from "@yamaha-admin-sb/button";
import { ModalContext } from "@/contexts/ModalContext";
import { ModalContractConfirm } from "../../modals/ModalContractConfirm";
import { ITechnicsDataTemplate, TechnicsForm } from "../TechnicsForm";
import { media } from "@/helpers/index";

const StyledFlags = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const WrappedButton = styled.div`
  ${media.tablet} {
    max-width: 325px;
  }
`;

const StyledError = styled.div`
  margin-top: 4px;
`;

interface IContractFormProps {
  lead: GetLeadPageQuery;
  onSuccess: () => void;
}

export const ContractForm: React.FC<IContractFormProps> = (props) => {
  const { lead, onSuccess } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const [form, setForm] = useState<UpdateContactPublicPageInput>({
    name: lead.getLeadPage.contact?.name || "",
    surname: lead.getLeadPage.contact?.surname || "",
    phone: lead.getLeadPage.contact?.phone || "",
    email: lead.getLeadPage.contact?.email || "",
    city: lead.getLeadPage.contact?.city || "",
    birthday: lead.getLeadPage.contact?.birthday
      ? lead.getLeadPage.contact?.birthday
      : "",
    gender: lead.getLeadPage.contact?.gender || null,
    passportSeries: lead.getLeadPage.contact?.passportSeries || "",
    passportNum: lead.getLeadPage.contact?.passportNum || "",
    passportIssue: lead.getLeadPage.contact?.passportIssue
      ? lead.getLeadPage.contact?.passportIssue
      : "",
    passportDepartment: lead.getLeadPage.contact?.passportDepartment || "",
    passportCode: lead.getLeadPage.contact?.passportCode || "",
  });
  const [technics, setTechnics] = useState<ITechnicsDataTemplate[]>(
    lead.getLeadPage.technics as ITechnicsDataTemplate[]
  );

  const handleChange = (
    key: keyof UpdateContactPublicPageInput,
    value: string
  ) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ mode: "onBlur", defaultValues: { ...form } });

  return (
    <>
      <form
        onSubmit={handleSubmit(() =>
          setModalContext((prev) => ({ ...prev, modalContractConfirm: true }))
        )}
      >
        <StyledFormItem>
          <StyledGridContainer>
            <StyledGridElement column="1 / span 2" color="gray-700">
              <Paragraph size={14} weight={500}>
                Личные данные
              </Paragraph>
            </StyledGridElement>
            <div>
              <Controller<IInput>
                name="name"
                label="Имя *"
                placeholder="Введите имя"
                control={control}
                value={form.name}
                error={errors?.name?.message}
                isBordered={true}
                rules={inputValidationRule}
                onChange={(value) => handleChange("name", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                name="surname"
                label="Фамилия *"
                control={control}
                error={errors?.surname?.message}
                placeholder="Введите фамилию"
                value={form.surname}
                isBordered={true}
                rules={inputValidationRule}
                onChange={(value) => handleChange("surname", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                name="patronymic"
                label="Отчество"
                control={control}
                placeholder="Введите отчество"
                value={form.patronymic}
                isBordered={true}
                onChange={(value) => handleChange("patronymic", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                name="phone"
                mask="+7 (999) 999-99-99"
                label="Телефон *"
                value={form.phone}
                placeholder="Введите телефон"
                control={control}
                error={errors?.phone?.message}
                isBordered={true}
                rules={phoneValidationRule}
                onChange={(value) => handleChange("phone", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                name="email"
                label="E-mail *"
                placeholder="Введите e-mail"
                isBordered={true}
                value={form.email}
                control={control}
                error={errors?.email?.message}
                rules={emailValidationRule}
                onChange={(value) => handleChange("email", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                name="birthday"
                mask="99.99.9999"
                label="Дата рождения *"
                placeholder="дд.мм.гггг"
                isBordered={true}
                control={control}
                value={form.birthday}
                error={errors?.birthday?.message}
                rules={inputValidationRule}
                onChange={(value) => handleChange("birthday", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<{
                onChange: (value: string) => void;
              }>
                control={control}
                rules={requiredValidationRule}
                name="gender"
                onChange={(value: GenderEnum) => handleChange("gender", value)}
                component={(inputProps: {
                  onChange: (value: string) => void;
                }) => (
                  <>
                    <div className="space-12">
                      <Paragraph
                        size={12}
                        color={errors?.gender?.message ? "red-400" : "gray-700"}
                        weight={500}
                      >
                        Пол *
                      </Paragraph>
                    </div>
                    <StyledFlags>
                      <Radio
                        name="gender"
                        value={form.gender === GenderEnum.Male}
                        onChange={() => inputProps.onChange(GenderEnum.Male)}
                      >
                        Мужской
                      </Radio>
                      <Radio
                        name="gender"
                        value={form.gender === GenderEnum.Female}
                        onChange={() => inputProps.onChange(GenderEnum.Female)}
                      >
                        Женский
                      </Radio>
                    </StyledFlags>
                    {errors?.gender?.message ? (
                      <StyledError>
                        <Paragraph size={12} color="red-400">
                          {errors?.gender?.message}
                        </Paragraph>
                      </StyledError>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              />
            </div>
          </StyledGridContainer>
        </StyledFormItem>
        <StyledFormItem>
          <StyledGridContainer>
            <StyledGridElement column="1 / span 2" color="gray-700">
              <Paragraph size={14} weight={500}>
                Паспортные данные
              </Paragraph>
            </StyledGridElement>
            <div>
              <Controller<IInput>
                name="passportSeries"
                mask="9999"
                label="Серия паспорта *"
                placeholder="xxxx"
                value={form.passportSeries}
                control={control}
                error={errors?.passportSeries?.message}
                isBordered={true}
                rules={inputValidationRule}
                onChange={(value) => handleChange("passportSeries", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                name="passportNum"
                mask="999999"
                label="Номер паспорта *"
                control={control}
                value={form.passportNum}
                error={errors?.passportNum?.message}
                placeholder="xxxxxx"
                isBordered={true}
                rules={inputValidationRule}
                onChange={(value) => handleChange("passportNum", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                name="passportIssue"
                label="Дата выдачи паспорта *"
                placeholder="дд.мм.гггг"
                mask="99.99.9999"
                value={form.passportIssue}
                isBordered={true}
                control={control}
                error={errors?.passportIssue?.message}
                rules={inputValidationRule}
                onChange={(value) => handleChange("passportIssue", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                name="passportDepartment"
                label="Отдел выдачи *"
                placeholder="Введите отдел выдачи"
                control={control}
                error={errors?.passportDepartment?.message}
                value={form.passportDepartment}
                isBordered={true}
                rules={inputValidationRule}
                onChange={(value) => handleChange("passportDepartment", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                name="passportCode"
                mask="999-999"
                label="Код подразделения *"
                placeholder="xxx-xxx"
                isBordered={true}
                control={control}
                error={errors?.passportCode?.message}
                value={form.passportCode}
                rules={inputValidationRule}
                onChange={(value) => handleChange("passportCode", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
            <div>
              <Controller<IInput>
                name="city"
                label="Адрес регистрации *"
                placeholder="Введите адрес регистрации"
                control={control}
                value={form.city}
                error={errors?.city?.message}
                isBordered={true}
                rules={inputValidationRule}
                onChange={(value) => handleChange("city", value)}
                component={(inputProps: IInput) => <Input {...inputProps} />}
              />
            </div>
          </StyledGridContainer>
        </StyledFormItem>
        <StyledFormItem>
          <TechnicsForm
            onUpdate={(data) => setTechnics(data)}
            technicsData={technics}
            control={control}
            errors={errors}
            setValue={setValue}
          />
          <WrappedButton>
            <Button type="submit" isBlock={true}>
              Сохранить и отправить на согласование
            </Button>
          </WrappedButton>
        </StyledFormItem>
      </form>
      {modalContext?.modalContractConfirm ? (
        <ModalContractConfirm
          contact={form}
          technics={technics}
          handleSuccess={onSuccess}
        />
      ) : (
        <></>
      )}
    </>
  );
};
