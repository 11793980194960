import styled from "styled-components";
import { colors } from "@/helpers/index";

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  border-bottom: 1px solid ${colors.gray200};
`;

export { StyledTitle };
