import { LeadResourceEnum, PaymentMethodEnum } from "@/graphql/generated";
import { PaymentTypeEnum } from "@/graphql/generated";
import { IDropdownItem } from "@yamaha-admin-sb/dropdown";

export const PAGINATION_DEFAULT_OPTIONS = {
  take: 40,
  skip: 0,
};

export const requiredValidationRule = {
  required: "Это поле обязательное",
};

export const emailValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const myEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return myEmailRegex.test(value) || "Неверный формат почты";
  },
};

export const emailValidationWithoutRequired = {
  validate: (value: string) => {
    const myEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!value) return true;
    return myEmailRegex.test(value) || "Неверный формат почты";
  },
};

export const codeValidationRule = (length: number) => ({
  required: "Это поле обязательное",
  validate: (value: string) => {
    return (
      value.replace(/[^\d]/g, "").length === length || "Код введен некорректно"
    );
  },
});

export const phoneValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const phoneRegExp = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
    return phoneRegExp.test(value) || "Неверный формат телефона";
  },
};

export const passwordValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    const myPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g;
    return (
      myPasswordRegex.test(value) ||
      "8 латинских символов, 1 прописная, заглавная буквы, 1 цифра"
    );
  },
};

export const inputValidationRule = {
  required: "Это поле обязательное",
  validate: (value: string) => {
    return !!value?.trim() || "Поле не должно быть пустым";
  },
};

export const LeadServiceSlugToText = {
  "car-selection": "Автоподбор",
  "offsite-diagnostics": "Выездная диагностика",
};

export const LeadServiceTypeToText = {
  "one-car": "1 автомобиль",
  "day-specialist": "Специалист на день",
  "car-selection": "Автоподбор",
};

export const ResourceEnumToText: { [key in LeadResourceEnum]: string } = {
  [LeadResourceEnum.Call]: "Телефон",
  [LeadResourceEnum.Site]: "Создана в системе",
  [LeadResourceEnum.Social]: "Социальная сеть",
};

export const StatusToText = {
  new: "Новая заявка",
  prefatory: "Обрабатывается",
  "to-be-agreed": "На согласовании",
  agreed: "Договор согласован",
  "prepayment-made": "Предоплата прошла",
  "in-progress": "В работе",
  "report-loaded": "Отчет загружен",
  "payment-made": "Оплата прошла",
  "lead-closed": "Завершена", // добавить кнопки завершить отменить внизу страницы
  successful: "Успешно завершен",
  canceled: "Заявка отменена",
};

export const PaymentTypeToText: { [key in PaymentTypeEnum]: string } = {
  [PaymentTypeEnum.Payment]: "Оплата",
  [PaymentTypeEnum.Prepayment]: "Предоплата",
};

export const PaymentMethodToText: { [key in PaymentMethodEnum]: string } = {
  [PaymentMethodEnum.Cash]: "Наличные",
  [PaymentMethodEnum.Cashless]: "Онлайн-перевод",
};

export const DRIVE_UNIT_OPTIONS: IDropdownItem[] = [
  {
    name: "Передний",
    value: "front",
  },
  {
    name: "Задний",
    value: "rear",
  },
  {
    name: "Полный",
    value: "full",
  },
];

export const BODY_OPTIONS: IDropdownItem[] = [
  {
    name: "Седан",
    value: "sedan",
  },
  {
    name: "Xэтчбек 5 дв",
    value: "hatch5",
  },
  {
    name: "Xэтчбек 3 дв.",
    value: "hatch3",
  },
  {
    name: "Лифтбек",
    value: "liftback",
  },
  {
    name: "Джип 5 дв.",
    value: "jeep5",
  },
  {
    name: "Джип 3 дв.",
    value: "jeep3",
  },
  {
    name: "Универсал",
    value: "saray",
  },
  {
    name: "Минивэн",
    value: "minivan",
  },
  {
    name: "Пикап",
    value: "pickup",
  },
  {
    name: "Купе",
    value: "coupe",
  },
  {
    name: "Открытый",
    value: "open",
  },
  {
    name: "Прочее",
    value: "other",
  },
];
