import React, { useEffect, useState } from "react";
import {
  ContactTypeEnum,
  GetLeadPageQuery,
  LeadReportOutput,
  PaymentTypeEnum,
  useGetLeadPageQuery,
} from "@/graphql/generated";
import { colors } from "@/helpers/styleColors";
import Default from "@/layouts/default";
import Cookies from "js-cookie";
import { Title } from "@yamaha-admin-sb/title";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";

import { Loader } from "@/components/Loader";
import { ContractForm } from "@/components/lead/stageItems/ContractForm";
import { ContractDocument } from "@/components/lead/stageItems/ContractDocument";
import { Report } from "@/components/lead/stageItems/Report";
import { ContractPreview } from "@/components/lead/ContractPreview";
import { Accept } from "@/components/lead/stageItems/Accept";
import { Payment } from "@/components/lead/stageItems/Payment";
import { Review } from "@/components/lead/stageItems/Review";
import { useQueryParams } from "@/hooks/useQueryParams";
import { useAddNotification } from "../hooks";
import { MainSummary } from "@/components/lead/MainSummary";
import { DottedMenu } from "@/components/DottedMenu";
import {
  StyledLaptopHidden,
  StyledLaptopReverseHidden,
} from "@/components/lead/leads.styled";
import { media } from "@/helpers/index";

interface ILead {
  path?: string | string[];
}

export type TBlockSlug = "confirm" | "payment" | "review";

const StyledMenuItem = styled.div`
  background-color: ${colors.white};
  box-shadow: 0px 6px 40px rgba(22, 35, 70, 0.1);
  border-radius: 4px;
`;

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid ${colors.gray200};
  padding: 20px 16px;
  ${media.tablet} {
    padding: 20px 32px;
  }
  ${media.laptop} {
    padding: 20px 40px;
  }
`;

const StyledMainForm = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1 1 100%;
`;

const Lead: React.FC<ILead> = () => {
  const { uuid = "" } = useParams();
  const notify = useAddNotification();
  const search = useQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isCompletedBlock, setIsCompletedBlock] = useState<{
    [key in TBlockSlug]: boolean;
  }>({
    confirm: false,
    payment: false,
    review: false,
  });

  const navigate = useNavigate();
  if (!Cookies.get("access")) {
    navigate(`/${uuid}/login`);
  }
  const { data, isLoading, refetch } = useGetLeadPageQuery({
    input: { access: Cookies.get("access") || "", path: uuid },
  });
  if (!data && !isLoading) {
    navigate(`/${uuid}/login`);
  }

  useEffect(() => {
    if (search?.paymentStauts === "success") {
      setSearchParams("");
      setIsCompletedBlock((prev) => ({ ...prev, payment: true }));
      notify("Оплата произведена", "success");
    }
  }, []);

  const leadType =
    data?.getLeadPage.lService?.slug === "offsite-diagnostics" ? "os" : "cs";

  const renderMainSummary = (data: GetLeadPageQuery) => {
    return <MainSummary data={data} />;
  };

  const phone = data?.getLeadPage?.client?.contacts?.find(contact => contact.type === ContactTypeEnum.Phone && contact.isMain);

  return !isLoading ? (
    data?.getLeadPage ? (
      <Default
        contact={{
          name: data.getLeadPage.contact?.name,
          surname: data.getLeadPage.contact?.surname,
        }}
      >
        <StyledTitle>
          <Title level={2} weight={500} color="gray-700">
            Заявка №{data?.getLeadPage.num}
          </Title>
          <StyledLaptopHidden>
            <DottedMenu>
              <StyledMenuItem>{renderMainSummary(data)}</StyledMenuItem>
            </DottedMenu>
          </StyledLaptopHidden>
        </StyledTitle>

        <div className="display-flex">
          <StyledMainForm>
            {data.getLeadPage.contracts?.length ? (
              <>
                <ContractDocument contracts={data.getLeadPage.contracts} />
                {!data.getLeadPage.leadStages?.find(
                  (item) =>
                    item.isCompleted &&
                    item.isFilled &&
                    item.stage?.slug === `${leadType}-contract`
                ) || isCompletedBlock.confirm ? (
                  <Accept
                    isCompleted={isCompletedBlock.confirm}
                    onCompleted={() => {
                      setIsCompletedBlock((prev) => ({
                        ...prev,
                        confirm: true,
                      }));
                    }}
                    phone={phone?.value ?? ""}
                    contractNumber={data?.getLeadPage?.num?.slice(-4)}
                  />
                ) : (
                  <></>
                )}

                {data.getLeadPage.payments &&
                  data.getLeadPage.payments.find(
                    (item) => item.type === PaymentTypeEnum.Prepayment
                  ) ? (
                  <Payment
                    payment={data.getLeadPage.payments.find(
                      (item) => item.type === PaymentTypeEnum.Prepayment
                    )}
                  />
                ) : (
                  <></>
                )}

                {data.getLeadPage.reports?.length ? (
                  <Report
                    reports={data.getLeadPage.reports as LeadReportOutput[]}
                  />
                ) : (
                  <></>
                )}

                {data.getLeadPage.payments &&
                  data.getLeadPage.payments.find(
                    (item) => item.type === PaymentTypeEnum.Payment
                  ) ? (
                  <Payment
                    payment={data.getLeadPage.payments.find(
                      (item) => item.type === PaymentTypeEnum.Payment
                    )}
                  />
                ) : (
                  <></>
                )}

                {(data?.getLeadPage?.leadStatuses &&
                  ["lead-closed", "successful"].includes(
                    data?.getLeadPage?.leadStatuses[0]?.status?.slug || ""
                  ) &&
                  !data.getLeadPage.rating?.id) ||
                  isCompletedBlock.review ? (
                  <Review
                    isSuccess={!!data.getLeadPage.rating?.id}
                    handleSuccess={() =>
                      setIsCompletedBlock((prev) => ({ ...prev, review: true }))
                    }
                    updateLead={refetch}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : data.getLeadPage.leadStages?.find(
              (item) => item.stage?.slug === `${leadType}-contract`
            ) &&
              data.getLeadPage.leadStatuses &&
              data.getLeadPage.leadStatuses[0].status?.slug !==
              "agreed-by-client" ? (
              <ContractForm lead={data} onSuccess={refetch} />
            ) : (
              <ContractPreview
                isSmall={false}
                form={{
                  ...data.getLeadPage.contact,
                  technics: data?.getLeadPage?.technics || [],
                }}
              />
            )}
          </StyledMainForm>

          <StyledLaptopReverseHidden>
            {renderMainSummary(data)}
          </StyledLaptopReverseHidden>
        </div>
      </Default>
    ) : (
      <></>
    )
  ) : (
    <StyledLoader>
      <Loader size="l" />
    </StyledLoader>
  );
};

export default Lead;
