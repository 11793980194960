import React, { useState } from "react";
import Default from "@/layouts/default";
import { Controller } from "@/components/Controller";
import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import {
  inputValidationRule,
  phoneValidationRule,
  requiredValidationRule,
} from "@/helpers/const";
import { useForm } from "react-hook-form";
import { IInput, Input } from "@yamaha-admin-sb/input";
import styled from "styled-components";
import { Button } from "@yamaha-admin-sb/button";
import { FormService } from "@/api/services/FormService";
import { useAddNotification } from "../hooks";
interface IForm {
  path?: string | string[];
}

export interface IRequestForm {
  contact: IContact;
  lSType: string;
  details: string;
}

interface IContact {
  name: string;
  surname?: string;
  phone?: string;
  email?: string;
}

const options: IDropdownItem[] = [
  { name: "Автоподбор", value: "car-selection" },
  { name: "Выездная диагностика", value: "one-car" },
  { name: "Специалист на день", value: "day-specialist" },
];

const WrappedForm = styled.form`
  width: 50vw;
  margin: 0 auto;
`;
const Form: React.FC<IForm> = () => {
  const notify = useAddNotification();

  const [requestForm, setRequestForm] = useState({
    name: "",
    surname: "",
    phone: "",
    email: "",
    lSType: "",
    details: "",
  });
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ mode: "onBlur" });

  const submitCallBack = async () => {
    try {
      await FormService.sendForm({
        contact: {
          name: requestForm.name,
          surname: requestForm.surname,
          email: requestForm.email,
          phone: requestForm.phone,
        },
        lSType: requestForm.lSType,
        details: requestForm.details,
      });
      notify("Форма успешно отправлена", "success");
    } catch (error) {
      console.log(error);
      notify("Error", "error");
    }
  };

  const handleChange = (key: string, value: string) => {
    setRequestForm((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <Default
      contact={{
        name: "S",
        surname: "A",
      }}
    >
      <WrappedForm onSubmit={handleSubmit(submitCallBack)}>
        <div className="space-20">
          <Controller<IInput>
            isBordered={true}
            name="name"
            label="Имя*"
            placeholder="Введите имя*"
            control={control}
            error={errors?.name?.message}
            rules={inputValidationRule}
            value={requestForm.name}
            onChange={(value) => handleChange("name", value)}
            component={(inputProps: IInput) => <Input {...inputProps} />}
          />
        </div>
        <div className="space-20">
          <Controller<IInput>
            isBordered={true}
            name="surname"
            label="Фамилия"
            placeholder="Введите фамилию"
            control={control}
            value={requestForm.surname}
            onChange={(value) => handleChange("surname", value)}
            component={(inputProps: IInput) => <Input {...inputProps} />}
          />
        </div>
        <div className="space-20">
          <Controller<IInput>
            mask="+7 (999) 999-99-99"
            name="phone"
            label="Телефон*"
            placeholder="Введите номер телефона*"
            isBordered={true}
            onChange={(value) => handleChange("phone", value)}
            control={control}
            error={errors?.phone?.message}
            value={requestForm.phone}
            rules={phoneValidationRule}
            component={(inputProps: IInput) => <Input {...inputProps} />}
          />
        </div>
        <div className="space-20">
          <Controller<IInput>
            isBordered={true}
            name="email"
            label="E-mail"
            placeholder="Введите e-mail"
            control={control}
            value={requestForm.email}
            error={errors?.email?.message}
            // rules={emailValidationRule}
            onChange={(value) => handleChange("email", value)}
            component={(inputProps: IInput) => <Input {...inputProps} />}
          />
        </div>
        <div className="space-20">
          <Controller<IDropdown>
            // portal={document.querySelector("body") as HTMLElement}
            name="lSType"
            label="Выберите услугу*"
            placeholder="Выберите услугу*"
            control={control}
            options={options}
            isBordered={true}
            error={errors.lSType?.message}
            rules={requiredValidationRule}
            onChange={(value: IDropdownItem[]) =>
              value[0].value && handleChange("lSType", value[0].value)
            }
            component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
          />
        </div>
        <div className="space-20">
          <Controller<IInput>
            isBordered={true}
            name="details"
            label="Ссылка"
            placeholder="Ссылка на авто"
            control={control}
            value={requestForm.details}
            onChange={(value) => handleChange("details", value)}
            component={(inputProps: IInput) => <Input {...inputProps} />}
          />
        </div>
        <Button isBlock={true} type="submit">
          Отправить
        </Button>
      </WrappedForm>
    </Default>
  );
};

export default Form;
