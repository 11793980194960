export const colors: any = {
  /*Grays Light-Dark*/
  lightColorNeutralWhite: 'var(--light-color-neutral-white)',
  darkColorNeutralWhite: 'var(--dark-color-neutral-white)',

  lightColorTransparent: 'var(--light-color-transparent)',
  darkColorTransparent: 'var(--dark-color-transparent)',

  lightColorGray100: 'var(--light-color-gray-100)',
  darkColorGray100: 'var(--dark-color-gray-100)',
  lightColorGray200: 'var(--light-color-gray-200)',
  darkColorGray200: 'var(--dark-color-gray-200)',
  lightColorGray300: 'var(--light-color-gray-300)',
  darkColorGray300: 'var(--dark-color-gray-300)',
  lightColorGray400: 'var(--light-color-gray-400)',
  darkColorGray400: 'var(--dark-color-gray-400)',
  lightColorGray500: 'var(--light-color-gray-500)',
  darkColorGray500: 'var(--dark-color-gray-500)',
  lightColorGray600: 'var(--light-color-gray-600)',
  darkColorGray600: 'var(--dark-color-gray-600)',
  lightColorGray700: 'var(--light-color-gray-700)',
  darkColorGray700: 'var(--dark-color-gray-700)',

  /*Primary Color Light-Dark*/
  lightColorBlue100: 'var(--light-color-blue-100)',
  darkColorBlue100: 'var(--dark-color-blue-100)',
  lightColorBlue200: 'var(--light-color-blue-200)',
  darkColorBlue200: 'var(--dark-color-blue-200)',
  lightColorBlue300: 'var(--light-color-blue-300)',
  darkColorBlue300: 'var(--dark-color-blue-300)',
  lightColorBlue400: 'var(--light-color-blue-400)',
  darkColorBlue400: 'var(--dark-color-blue-400)',
  lightColorBlue500: 'var(--light-color-blue-500)',
  darkColorBlue500: 'var(--dark-color-blue-500)',

  /*Red Light-Dark*/
  lightColorRed100: 'var(--light-color-red-100)',
  darkColorRed100: 'var(--dark-color-red-100)',
  lightColorRed200: 'var(--light-color-red-200)',
  darkColorRed200: 'var(--dark-color-red-200)',
  lightColorRed300: 'var(--light-color-red-300)',
  darkColorRed300: 'var(--dark-color-red-300)',
  lightColorRed400: 'var(--light-color-red-400)',
  darkColorRed400: 'var(--dark-color-red-400)',
  lightColorRed500: 'var(--light-color-red-500)',
  darkColorRed500: 'var(--dark-color-red-500)',

  /*Green Light-Dark*/
  lightColorGreen100: 'var(--light-color-green-100)',
  darkColorGreen100: 'var(--dark-color-green-100)',
  lightColorGreen200: 'var(--light-color-green-200)',
  darkColorGreen200: 'var(--dark-color-green-200)',
  lightColorGreen300: 'var(--light-color-green-300)',
  darkColorGreen300: 'var(--dark-color-green-300)',
  lightColorGreen400: 'var(--light-color-green-400)',
  darkColorGreen400: 'var(--dark-color-green-400)',
  lightColorGreen500: 'var(--light-color-green-500)',
  darkColorGreen500: 'var(--dark-color-green-500)',

  /*Orange Light-Dark*/
  lightColorOrange100: 'var(--light-color-orange-100)',
  darkColorOrange100: 'var(--dark-color-orange-100)',
  lightColorOrange200: 'var(--light-color-orange-200)',
  darkColorOrange200: 'var(--dark-color-orange-200)',
  lightColorOrange300: 'var(--light-color-orange-300)',
  darkColorOrange300: 'var(--dark-color-orange-300)',

  /*Yellow Light-Dark*/
  lightColorYellow100: 'var(--light-color-yellow-100)',
  darkColorYellow100: 'var(--dark-color-yellow-100)',
  lightColorYellow200: 'var(--light-color-yellow-200)',
  darkColorYellow200: 'var(--dark-color-yellow-200)',
  lightColorYellow300: 'var(--light-color-yellow-300)',
  darkColorYellow300: 'var(--dark-color-yellow-300)',

  /*Link Color Light-Dark*/
  lightColorLink100: 'var(--light-color-link-100)',
  darkColorLink100: 'var(--dark-color-link-100)',
}

export const themeDarkColor: { [key: string]: string } = {
  'neutral-white': colors.darkColorNeutralWhite,
  transparent: colors.darkColorTransparent,
  'gray-100': colors.darkColorGray100,
  'gray-200': colors.darkColorGray200,
  'gray-300': colors.darkColorGray300,
  'gray-400': colors.darkColorGray400,
  'gray-500': colors.darkColorGray500,
  'gray-600': colors.darkColorGray600,
  'gray-700': colors.darkColorGray700,
  'blue-100': colors.darkColorBlue100,
  'blue-200': colors.darkColorBlue200,
  'blue-300': colors.darkColorBlue300,
  'blue-400': colors.darkColorBlue400,
  'blue-500': colors.darkColorBlue500,
  'red-100': colors.darkColorRed100,
  'red-200': colors.darkColorRed200,
  'red-300': colors.darkColorRed300,
  'red-400': colors.darkColorRed400,
  'red-500': colors.darkColorRed500,
  'green-100': colors.darkColorGreen100,
  'green-200': colors.darkColorGreen200,
  'green-300': colors.darkColorGreen300,
  'green-400': colors.darkColorGreen400,
  'green-500': colors.darkColorGreen500,
  'orange-100': colors.darkColorOrange100,
  'orange-200': colors.darkColorOrange200,
  'orange-300': colors.darkColorOrange300,
  'yellow-100': colors.darkColorYellow100,
  'yellow-200': colors.darkColorYellow200,
  'yellow-300': colors.darkColorYellow300,
  'link-100': colors.darkColorLink100,
  background: '#E5E5E5',
  '--color-background': '#F5F5F5',
  '--color-black': '#000000',
}

export const themeLightColor: { [key: string]: string } = {
  'neutral-white': colors.lightColorNeutralWhite,
  transparent: colors.lightColorTransparent,
  'gray-100': colors.lightColorGray100,
  'gray-200': colors.lightColorGray200,
  'gray-300': colors.lightColorGray300,
  'gray-400': colors.lightColorGray400,
  'gray-500': colors.lightColorGray500,
  'gray-600': colors.lightColorGray600,
  'gray-700': colors.lightColorGray700,
  'blue-100': colors.lightColorBlue100,
  'blue-200': colors.lightColorBlue200,
  'blue-300': colors.lightColorBlue300,
  'blue-400': colors.lightColorBlue400,
  'blue-500': colors.lightColorBlue500,
  'red-100': colors.lightColorRed100,
  'red-200': colors.lightColorRed200,
  'red-300': colors.lightColorRed300,
  'red-400': colors.lightColorRed400,
  'red-500': colors.lightColorRed500,
  'green-100': colors.lightColorGreen100,
  'green-200': colors.lightColorGreen200,
  'green-300': colors.lightColorGreen300,
  'green-400': colors.lightColorGreen400,
  'green-500': colors.lightColorGreen500,
  'orange-100': colors.lightColorOrange100,
  'orange-200': colors.lightColorOrange200,
  'orange-300': colors.lightColorOrange300,
  'yellow-100': colors.lightColorYellow100,
  'yellow-200': colors.lightColorYellow200,
  'yellow-300': colors.lightColorYellow300,
  'link-100': colors.lightColorLink100,
  background: '#E5E5E5',
  '--color-background': '#F5F5F5',
  '--color-black': '#000000',
}
