import { Notify } from "@yamaha-admin-sb/notification";

const NOTIFY_ICON: { [key: string]: string } = {
  success: "check",
  error: "cross",
  warning: "123",
  default: "123",
};

const useAddNotification = () => {
  return (
    text: string | JSX.Element,
    type: "success" | "error" | "warning" | "default",
  ) => {
    return Notify[type].call(null, {
      children: text,
      iconName: NOTIFY_ICON[type],
    });
  };
};

export default useAddNotification;
