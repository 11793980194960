import { GetLeadPageInput, useGetLeadPageQuery } from "@/graphql/generated";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import useAddNotification from "./useNotification";

export const useGetLead = ({ onSuccess }: { onSuccess?: () => void }) => {
  const notify = useAddNotification();
  const [loginParams, setLoginParams] = useState<GetLeadPageInput>({
    access: "",
    path: "",
  });
  const [isStale, setIsStale] = useState(false);
  const {
    data,
    isStale: isStaleLead,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetLeadPageQuery(
    { input: loginParams },
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!loginParams.access && !!loginParams.path && isStale,
    },
  );

  const onGetLeadPage = (data: GetLeadPageInput) => {
    setLoginParams(data);
  };

  const onError = (error: ErrorEvent) => {
    notify(error?.message, "error");
  };
  useEffect(() => {
    if (isSuccess) {
      Cookies.set("access", loginParams.access, { expires: 5 });
      onSuccess && onSuccess();
    }
  }, [isSuccess]);
  useEffect(() => setIsStale(isStaleLead), [isStaleLead]);
  useEffect(() => {
    isError && onError(error as ErrorEvent);
  }, [isError]);

  return { data, isLoading, onGetLeadPage };
};
