import React from "react";
import { toFormatPrice } from "@/helpers/utils";
import { Button } from "@yamaha-admin-sb/button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Title } from "@yamaha-admin-sb/title";
import styled from "styled-components";
import { DataFilled } from "../DataFilled";
import { StyledFormItem } from "../leads.styled";
import {
  PaymentEntity,
  PaymentMethodEnum,
  PaymentTypeEnum,
  useProcessPaymentMutation,
  PaymentStatusEnum,
} from "@/graphql/generated";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import useAddNotification from "@/hooks/useNotification";

interface IPaymentProps {
  payment?: Pick<
    PaymentEntity,
    | "id"
    | "type"
    | "method"
    | "amount"
    | "status"
    | "createdAt"
    | "externalOrderId"
    | "formUrl"
  >;
}

const StyledForm = styled.form`
  max-width: 368px;
`;

const StyledPaymentWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledPaymentLink = styled.a`
  font-size: 12px;
`;

const paymentText: { [key in PaymentTypeEnum]: string } = {
  Payment: "Оплат",
  Prepayment: "Предоплат",
};

const PaymentStatusToText: {
  [key in PaymentStatusEnum]: { color?: string; text: string };
} = {
  Canceled: { text: "Оплата отменена", color: "red-200" },
  Processed: { text: "Оплата прошла", color: "green-200" },
  Processing: { text: "Оплата в процессе", color: "orange-200" },
  Refunded: { text: "Осуществлен возврат", color: "green-200" },
  ToProcess: { text: "ToProcess", color: "orange-200" },
  Unprocessable: { text: "Unprocessable", color: "red-200" },
};

export const Payment: React.FC<IPaymentProps> = (props) => {
  const { payment } = props;
  const notify = useAddNotification();
  const { uuid = "" } = useParams();
  const { mutate: generateLink, isLoading } = useProcessPaymentMutation();

  const handleGenerateLink = () => {
    payment?.id &&
      generateLink(
        {
          access: {
            path: uuid,
            access: Cookies.get("access") || "",
          },
          input: {
            id: payment?.id,
          },
        },
        {
          onSuccess: (response) => {
            response.processPayment.formUrl &&
              window.open(response.processPayment.formUrl, "_self");
          },
          onError: (error) => notify((error as ErrorEvent)?.message, "error"),
        }
      );
  };
  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          {` ${paymentText[payment?.type || ""]}а`}
        </Title>
      </div>
      {payment?.method === PaymentMethodEnum.Cashless ? (
        payment?.status === PaymentStatusEnum.ToProcess ? (
          <StyledForm>
            {payment?.amount ? (
              <div className="space-20">
                <Paragraph size={12} color="gray-600">
                  {`Вам необходимо внести  ${paymentText[
                    payment?.type || ""
                  ].toLowerCase()}у в размере ${toFormatPrice(
                    payment?.amount
                  )} ₽`}
                </Paragraph>
              </div>
            ) : (
              <></>
            )}

            <div className="space-20">
              <Button
                isLoading={isLoading}
                onClick={() => {
                  handleGenerateLink();
                }}
              >
                {`Перейти к ${paymentText[payment?.type || ""].toLowerCase()}е`}
              </Button>
            </div>
          </StyledForm>
        ) : payment?.status === PaymentStatusEnum.Processing ? (
          <StyledPaymentWrapper>
            <DataFilled
              text={PaymentStatusToText[payment?.status || ""].text}
              color={PaymentStatusToText[payment?.status || ""].color}
            />
            <StyledPaymentLink href={payment.formUrl || ""} target="_blank">
              Открыть еще раз
            </StyledPaymentLink>
          </StyledPaymentWrapper>
        ) : (
          <DataFilled
            text={PaymentStatusToText[payment?.status || ""].text}
            color={PaymentStatusToText[payment?.status || ""].color}
          />
        )
      ) : (
        <Paragraph size={16}>Оплата специалисту</Paragraph>
      )}
    </StyledFormItem>
  );
};
