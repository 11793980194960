import { colors } from "@/helpers/styleColors";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import React from "react";
import styled from "styled-components";

interface IModalTop {
  title: string;
  text?: string;
  className?: string;
  onBackClick?: () => void;
  onClose?: () => void;
}

const StyledModalTop = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding: 20px 24px;
  border-bottom: 1px solid ${colors.gray200};
`;
const StyledCloseButtonWrap = styled.div``;

const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledText = styled.div`
  margin-top: 12px;
`;

const ModalTop: React.FC<IModalTop> = (props) => {
  const { title, text, className, onBackClick, onClose } = props;
  return (
    <StyledModalTop className={className}>
      <div>
        <StyledTitleWrapper>
          {onBackClick ? (
            <IconButton
              size="m"
              icon="arrow-left"
              color="gray"
              variant="tertiary"
              onClick={onBackClick}
            />
          ) : (
            <></>
          )}
          <Paragraph size={14}>{title}</Paragraph>
        </StyledTitleWrapper>

        {text ? (
          <StyledText>
            <Paragraph size={12} color="gray-700">
              {text}
            </Paragraph>
          </StyledText>
        ) : (
          <></>
        )}
      </div>
      {onClose ? (
        <StyledCloseButtonWrap>
          <IconButton
            size="m"
            icon="cross"
            color="gray"
            variant="tertiary"
            onClick={onClose}
          />
        </StyledCloseButtonWrap>
      ) : (
        <></>
      )}
    </StyledModalTop>
  );
};

export default ModalTop;
