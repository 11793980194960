import * as AWS from 'aws-sdk';

const s3Params =
{
    endpoint: process?.env?.REACT_APP_S3_ENDPOINT,
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
    s3ForcePathStyle: false,
    signatureVersion: 'v4',
}

export const s3Client = new AWS.S3(s3Params);