import { ServiceBase } from "@/api/core";
import { IRequestForm } from "@/pages/form";
const apiUrl =
  "https://master-autopragmat-crm-router.autopragmat.outside.dev/crm/api/v1";
export class FormService extends ServiceBase {
  public static sendForm(data: IRequestForm): Promise<any> {
    const url = "/leads";

    return this.callApi({
      method: "POST",
      url,
      data,
      baseURL: apiUrl,
    });
  }
}
