import { media } from "@/helpers/index";
import styled, { css } from "styled-components";

export const ModalBody = styled.div<{
  disableOverflow?: boolean;
  minHeight?: number;
}>`
  padding: 24px;
  max-height: 70vh;
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight}px;
    `}
  overflow: ${({ disableOverflow }) => (disableOverflow ? "visible" : "auto")};
`;

export const ModalFooter = styled.footer`
  padding: 8px 24px 24px;
  display: grid;
  gap: 8px;
  button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  ${media.tablet} {
    display: flex;
    justify-content: flex-end;
    button {
      width: fit-content;
    }
    button:not(:last-child) {
      margin-right: 8px;
    }
  }
`;
