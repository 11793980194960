import { DocumentPreview } from "@/components/DocumentPreview";
import { ContractEntity, Maybe } from "@/graphql/generated";
import { downloadURL } from "@/helpers/utils";
import { Title } from "@yamaha-admin-sb/title";
import React from "react";
import { StyledFormItem, StyledDocumentsList } from "../leads.styled";
import { getDocument } from "@/hooks/getDocument";

interface IContractDocument {
  contracts?: Maybe<
    Pick<
      ContractEntity,
      "id" | "createdAt" | "url" | "fileSize" | "filePath" | "fileName"
    >[]
  >;
}



export const ContractDocument: React.FC<IContractDocument> = (props) => {
  const { contracts } = props;


  const fetchFile = async (key: string) => {
    return await getDocument({ key });
  };


  const handleDownloadClick = async (id: number) => {
    const link = contracts && contracts.find((rep) => rep.id === id)?.filePath;
    if (!link) return;
    const fileUrl = await fetchFile(link);
    const filename = link.split("/").pop() as string;
    if (!fileUrl) return;
    await downloadURL(fileUrl, filename);
  };

  const handleViewClick = async (id: number) => {
    const link = contracts && contracts.find((rep) => rep.id === id)?.filePath;
    if (!link) return;
    const fileUrl = await fetchFile(link);
    window.open(fileUrl, "_blank");
  };
  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Договор
        </Title>
      </div>
      {contracts?.length ? (
        <StyledDocumentsList>
          {contracts.map((item) => (
            <DocumentPreview
              key={item.id}
              name={item.fileName || "Документ"}
              size={item.fileSize}
              onDownload={() => handleDownloadClick(item.id)}
              onView={() => handleViewClick(item.id)}
            />
          ))}
        </StyledDocumentsList>
      ) : (
        <></>
      )}
    </StyledFormItem>
  );
};
