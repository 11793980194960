import { IColor } from "@/helpers/interfaces";

export const colors: IColor = {
  white: "var(--color-white)",
  black: "var(--color-black)",
  gray900: "var(--color-gray-900)",
  gray800: "var(--color-gray-800)",
  gray700: "var(--color-gray-700)",
  gray500: "var(--color-gray-500)",
  gray400: "var(--color-gray-400)",
  gray300: "var(--color-gray-300)",
  gray200: "var(--color-gray-200)",
  gray100: "var(--color-gray-100)",
  red100: "var(--color-red-100)",
  red200: "var(--color-red-200)",
  brandBlue: "var(--color-brand-blue)",
  blue: "var(--color-blue)",
  cian: "var(--color-cian)",
  yellow: "var(--color-yellow)",
  orange: "var(--color-orange)",
  green: "var(--color-green)",
  green100: "var(--color-green-100)",
  green200: "var(--color-green-200)",
  red: "var(--color-red)",
  pink: "var(--color-pink)",
  brand: "var(--color-brand)",
  error: "var(--color-error)",
  warning: "var(--color-warning)",
  buttonPrimary: "var(--color-button-primary)",
  buttonSecondary: "var(--color-button-secondary)",
};
