import React, { useState } from "react";
import { toFormatPrice } from "@/helpers/utils";
import { Button } from "@yamaha-admin-sb/button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Title } from "@yamaha-admin-sb/title";
import styled from "styled-components";
import { DataFilled } from "../DataFilled";
import { StyledFormItem } from "../leads.styled";
import { Icon } from "@yamaha-admin-sb/icon";
import { Textarea } from "@yamaha-admin-sb/textarea";
import { useCreateRatingMutation } from "@/graphql/generated";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import useAddNotification from "@/hooks/useNotification";

interface IReviewProps {
  isSuccess: boolean;
  updateLead: () => void;
  handleSuccess: () => void;
}

const StyledForm = styled.form`
  max-width: 414px;
`;

const StyledStars = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StyledStar = styled.div`
  cursor: pointer;
`;

export const Review: React.FC<IReviewProps> = (props) => {
  const { isSuccess, updateLead, handleSuccess } = props;
  const { uuid = "" } = useParams();
  const notify = useAddNotification();

  const [hoverStar, setHoverStar] = useState(-1);
  const { mutate: createRating, isLoading } = useCreateRatingMutation();
  const [form, setForm] = useState({
    value: 0,
    comment: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    createRating(
      {
        input: form,
        access: { path: uuid, access: Cookies.get("access") || "" },
      },
      {
        onSuccess: () => {
          handleSuccess();
          notify("Отзыв упешно отправлен", "success");
          updateLead();
        },
        onError: (error) => notify((error as ErrorEvent)?.message, "error"),
      },
    );
  };
  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Комментарий о работе
        </Title>
      </div>
      {!isSuccess ? (
        <StyledForm onSubmit={handleSubmit}>
          <div className="space-8">
            <Paragraph size={12} weight={500}>
              Оценка
            </Paragraph>
          </div>
          <StyledStars
            onMouseLeave={() => setHoverStar(0)}
            className="space-40"
          >
            {Array.from(Array(5).keys()).map((_, i) => (
              <StyledStar
                onMouseEnter={() => setHoverStar(i + 1)}
                onClick={() => setForm((prev) => ({ ...prev, value: i + 1 }))}
              >
                <Icon
                  name="star-filled"
                  color={
                    i + 1 <= hoverStar || (i + 1 <= form.value && !hoverStar)
                      ? "yellow-300"
                      : "gray-300"
                  }
                  size={24}
                />
              </StyledStar>
            ))}
          </StyledStars>

          <div className="space-20">
            <Textarea
              name="comment"
              label="Комментарий"
              placeholder="Введите комментарий"
              value={form.comment || ""}
              onChange={(value) =>
                setForm((prev) => ({ ...prev, comment: value }))
              }
            />
          </div>
          <Button isLoading={isLoading} type="submit">
            Отправить
          </Button>
        </StyledForm>
      ) : (
        <DataFilled text="Ваш комментарий успешно отправлен" />
      )}
    </StyledFormItem>
  );
};
// yellow
// gray
