import styled from "styled-components";

export const StyledTableWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* overflow: auto; */
`;

export const StyledTable = styled.div`
  border-radius: 12px;
  overflow: hidden;
`;

export const StyledTablePagination = styled.div`
  margin-top: 20px;
  align-self: center;
`;
