import { GenderEnum } from "@/graphql/generated";
import { colors, media } from "@/helpers/index";
import { IContractForm } from "@/helpers/interfaces";
import { toFormatPrice } from "@/helpers/utils";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import React from "react";
import styled, { css } from "styled-components";
import { toDateFormat } from "../modals/ModalContractConfirm";
import { gearboxOptions } from "./TechnicsForm";

interface IContractPreview {
  form: IContractForm;
  isSmall: boolean;
}

const StyledContractFieldItem = styled.div<{ isSmall: boolean }>`
  padding: ${({ isSmall }) => (isSmall ? "0 0 28px 0" : "32px 40px 36px")};
  ${({ isSmall }) =>
    !isSmall &&
    css`
      padding: 32px 16px 36px;
      ${media.tablet} {
        padding: 32px 32px 36px;
      }
      ${media.laptop} {
        padding: 32px 40px 36px;
      }
    `}
  border-bottom: 1px solid ${colors.gray200};
  margin-bottom: 24px;
`;

const StyledListItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;
const StyledListName = styled.div`
  width: 135px;
  margin-right: 40px;
`;
const StyledListValue = styled.div``;

const genderToText: { [key in GenderEnum]: string } = {
  Male: "Мужской",
  Female: "Женский",
};

export const ContractPreview: React.FC<IContractPreview> = (props) => {
  const { form, isSmall } = props;
  return (
    <>
      <StyledContractFieldItem isSmall={isSmall}>
        <div className="space-28">
          <Paragraph size={isSmall ? 12 : 14} weight={500} color="gray-700">
            Личные данные
          </Paragraph>
        </div>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Имя
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.name || ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Фамилия
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.surname || ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Отчество
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.patronymic || ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Телефон
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.phone || ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              E-mail
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.email || ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Место регистрации
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.city || ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Год рождения
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form.birthday && toDateFormat && new Date(toDateFormat(form.birthday) ?? '').toLocaleDateString()}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Пол
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.gender ? genderToText[form?.gender] : ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
      </StyledContractFieldItem>
      <StyledContractFieldItem isSmall={isSmall}>
        <div className="space-28">
          <Paragraph size={isSmall ? 12 : 14} weight={500} color="gray-700">
            Паспортные данные
          </Paragraph>
        </div>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Серия паспорта
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.passportSeries || ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Номер паспорта
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.passportNum || ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Дата выдачи паспорта
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {new Date(toDateFormat(form.passportIssue) ?? '').toLocaleDateString()}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Отдел выдачи
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.passportDepartment || ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
        <StyledListItem>
          <StyledListName>
            <Paragraph size={12} color="gray-600" weight={500}>
              Код подразделения
            </Paragraph>
          </StyledListName>
          <StyledListValue>
            <Paragraph size={12} color="gray-700" weight={400}>
              {form?.passportCode || ""}
            </Paragraph>
          </StyledListValue>
        </StyledListItem>
      </StyledContractFieldItem>

      <StyledContractFieldItem isSmall={isSmall}>
        <div className="space-24">
          <Paragraph size={isSmall ? 12 : 14} weight={500} color="gray-700">
            Данные выездной диагностики
          </Paragraph>
        </div>
        {form?.technics?.length ? (
          form?.technics
            .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
            .map((item, index) => (
              <React.Fragment key={index}>
                <div className="space-28">
                  <Paragraph size={12} weight={500} color="gray-600">
                    Автомобиль №{index + 1}
                  </Paragraph>
                </div>
                <StyledListItem>
                  <StyledListName>
                    <Paragraph size={12} color="gray-600" weight={500}>
                      Марка
                    </Paragraph>
                  </StyledListName>
                  <StyledListValue>
                    <Paragraph size={12} color="gray-700" weight={400}>
                      {item?.make || ""}
                    </Paragraph>
                  </StyledListValue>
                </StyledListItem>
                {item.model ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Модель
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <Paragraph size={12} color="gray-700" weight={400}>
                        {item?.model || ""}
                      </Paragraph>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
                {item.gearbox ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Коробка передач
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <Paragraph size={12} color="gray-700" weight={400}>
                        {item.gearbox
                          ?.map(
                            (item) =>
                              gearboxOptions.find(
                                (gearboxItem) => gearboxItem.value === item
                              )?.name || ""
                          )
                          .join(", ")}
                      </Paragraph>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
                {item.driveUnit ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Привод
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <Paragraph size={12} color="gray-700" weight={400}>
                        {item.driveUnit.join(", ")}
                      </Paragraph>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
                {item.body ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Кузов
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <Paragraph size={12} color="gray-700" weight={400}>
                        {item.body.join(", ")}
                      </Paragraph>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
                {item.engineVolume ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Объем двигателя
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <Paragraph size={12} color="gray-700" weight={400}>
                        {`${item.engineVolume} л`}
                      </Paragraph>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
                {item.mileage ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Пробег
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <Paragraph size={12} color="gray-700" weight={400}>
                        {`До ${toFormatPrice(item.mileage)} км`}
                      </Paragraph>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
                {item.issueYear ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Год выпуска
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <Paragraph size={12} color="gray-700" weight={400}>
                        {item?.issueYear || ""}
                      </Paragraph>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
                {item.color ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Цвет
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <Paragraph size={12} color="gray-700" weight={400}>
                        {item?.color || ""}
                      </Paragraph>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
                {item?.maxPrice || item?.maxPrice ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Цена
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <Paragraph size={12} color="gray-700" weight={400}>
                        {`${item.minPrice ? toFormatPrice(item.minPrice) + " ₽" : ""
                          } ${item.maxPrice ? `- ${toFormatPrice(item.maxPrice)}` : ""
                          } ₽`}
                      </Paragraph>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
                {item.parameters ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Ссылка на авто
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <a href={item.parameters} target="_blank" rel="noreferrer">
                        <Paragraph size={12} color="blue-200" weight={500}>
                          Link
                        </Paragraph>
                      </a>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
                {item.equipmentOptions ? (
                  <StyledListItem>
                    <StyledListName>
                      <Paragraph size={12} color="gray-600" weight={500}>
                        Комплектация. Необходимые опции
                      </Paragraph>
                    </StyledListName>
                    <StyledListValue>
                      <Paragraph size={12} color="gray-700" weight={400}>
                        {item.equipmentOptions}
                      </Paragraph>
                    </StyledListValue>
                  </StyledListItem>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))
        ) : (
          <></>
        )}
      </StyledContractFieldItem>
    </>
  );
};
