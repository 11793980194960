import { GetLeadPageQuery, useGetLServicesQuery } from "@/graphql/generated";
import {
  LeadServiceSlugToText,
  LeadServiceTypeToText,
  ResourceEnumToText,
} from "@/helpers/const";
import { toFormatPrice } from "@/helpers/utils";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { Title } from "@yamaha-admin-sb/title";
import React, { useMemo } from "react";
import {
  StyledInfoItem,
  StyledNameItem,
  StyledValueItem,
  WrappedLeadTitle,
  WrappedBlock,
} from "../leads.styled";

interface IRequsetBlock {
  isOffsiteDiagnostics: boolean;
  lead: GetLeadPageQuery;
}

export const RequestBlock: React.FC<IRequsetBlock> = (props) => {
  const { lead, isOffsiteDiagnostics } = props;

  return (
    <WrappedBlock>
      <WrappedLeadTitle className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Заявка
        </Title>
      </WrappedLeadTitle>
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Номер
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          <Paragraph size={12} color="gray-700" weight={400}>
            №{lead.getLeadPage.num}
          </Paragraph>
        </StyledValueItem>
      </StyledInfoItem>
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Тип
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          <Paragraph size={12} color="gray-700" weight={400}>
            {LeadServiceSlugToText[lead?.getLeadPage?.lService?.slug || ""]}
          </Paragraph>
        </StyledValueItem>
      </StyledInfoItem>
      {isOffsiteDiagnostics ? (
        <StyledInfoItem>
          <StyledNameItem>
            <Paragraph size={12} color="gray-600" weight={500}>
              Услуга
            </Paragraph>
          </StyledNameItem>
          <StyledValueItem>
            <Paragraph
              size={12}
              color={lead.getLeadPage.lSType?.slug ? "gray-700" : "gray-500"}
              weight={400}
            >
              {lead.getLeadPage.lSType?.slug
                ? LeadServiceTypeToText[lead.getLeadPage.lSType?.slug]
                : "Не выбрана"}
            </Paragraph>
          </StyledValueItem>
        </StyledInfoItem>
      ) : (
        <></>
      )}
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Источник
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          <Paragraph size={12} color="gray-700" weight={400}>
            {ResourceEnumToText[lead.getLeadPage.resource]}
          </Paragraph>
        </StyledValueItem>
      </StyledInfoItem>
      <StyledInfoItem>
        <StyledNameItem>
          <Paragraph size={12} color="gray-600" weight={500}>
            Сумма
          </Paragraph>
        </StyledNameItem>
        <StyledValueItem>
          <Paragraph
            size={12}
            color={lead.getLeadPage?.toPaid ? "gray-700" : "gray-500"}
            weight={400}
          >
            {lead?.getLeadPage?.toPaid
              ? `${toFormatPrice(lead.getLeadPage.toPaid)} ₽`
              : "Не указана"}
          </Paragraph>
        </StyledValueItem>
      </StyledInfoItem>
    </WrappedBlock>
  );
};
