import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { keyframes } from "@emotion/react";
import { Icon } from "@yamaha-admin-sb/icon";

type Size = "m" | "l" | "s";

export interface ILoader {
  size?: Size;
}

const rotation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const StyledLoader = styled.span<{ size?: Size }>`
  display: inline-block;

  ${({ size }) =>
    size === "l"
      ? css`
          width: 48px;
          height: 48px;
        `
      : size === "m"
      ? css`
          width: 24px;
          height: 24px;
        `
      : css`
          width: 16px;
          height: 16px;
        `}

  animation: ${rotation} 1s infinite;
`;

const Loader: React.FC<ILoader> = (props): JSX.Element => {
  const { size = "m" } = props;

  const iconSize = () => {
    if (size === "s") return 16;
    if (size === "m") return 24;
    if (size === "l") return 48;
    return 16;
  };

  return (
    <StyledLoader size={size}>
      <Icon name={`loader-${size}`} size={iconSize()} color="blue-200" />
    </StyledLoader>
  );
};

export { Loader };
