import { ModalContext } from "@/contexts/ModalContext";

import React, { useContext } from "react";
import ModalTop from "@/components/modals/ModalTop";
import { Modal } from "@/components/modals/Modal";
import { ModalBody, ModalFooter } from "@/components/styled";
import { Button } from "@yamaha-admin-sb/button";
import { ContractPreview } from "../lead/ContractPreview";
import {
  TechnicOutput,
  UpdateContactPublicPageInput,
  useUpdateLeadPublicPageMutation,
} from "@/graphql/generated";
import { ITechnicsDataTemplate } from "../lead/TechnicsForm";
import { IContractForm } from "@/helpers/interfaces";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import useAddNotification from "@/hooks/useNotification";

export interface IModal {
  contact: UpdateContactPublicPageInput;
  technics: ITechnicsDataTemplate[];
  handleSuccess: () => void;
}

export const toDateFormat = (date: string) => {
  console.log("date", date);
  if (!date) return;
  const dateArr = date?.split(".");

  if (dateArr?.length > 2) {
    return new Date(`${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`);
  }
  return date;
};

export const ModalContractConfirm: React.FC<IModal> = (props): JSX.Element => {
  const { contact, technics, handleSuccess } = props;
  const { uuid = "" } = useParams();
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);
  const { mutate: updateLead, isLoading } = useUpdateLeadPublicPageMutation();
  const notify = useAddNotification();
  const handleButtonUndoClick = () => {
    setModalContext((prevState) => ({
      ...prevState,
      modalContractConfirm: false,
    }));
  };
  const onSubmit = () => {
    updateLead(
      {
        input: {
          contact: {
            ...contact,
            birthday: toDateFormat(contact.birthday),
            passportIssue: toDateFormat(contact.passportIssue),
          },
          technics: technics.map((item) => ({
            ...item,
            driveUnit: item.driveUnit || [],
            gearbox: item.gearbox || [],
            model: item.model || "",
            body: item.body || [],
          })) as TechnicOutput[],
        },
        access: {
          path: uuid,
          access: Cookies.get("access") || "",
        },
      },
      {
        onSuccess: async () => {
          notify("Данные подтверждены", "success");
          handleSuccess();
          handleButtonUndoClick();
        },
        onError: (error) => {
          notify(
            (error as ErrorEvent)?.message || "Неизвестная ошибка",
            "error"
          );
        },
      }
    );
  };
  return (
    <Modal name="modalContractConfirm">
      <ModalTop
        title="Данные для договора"
        text="Пожалуйста, проверьте правильность введенных данных"
        onClose={handleButtonUndoClick}
      />

      <ModalBody>
        <ContractPreview
          form={
            {
              ...contact,
              birthday: contact.birthday,
              passportIssue: contact.passportIssue,
              technics,
            } as IContractForm
          }
          isSmall={true}
        />
      </ModalBody>

      <ModalFooter>
        <Button
          variant="secondary"
          color="gray"
          onClick={handleButtonUndoClick}
        >
          Отменить
        </Button>
        <Button isLoading={isLoading} onClick={() => onSubmit()}>
          Подтвердить
        </Button>
      </ModalFooter>
    </Modal>
  );
};
