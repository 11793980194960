import { AttachmentOutput } from "@/graphql/generated";
import React, { useState } from "react";
import styled from "styled-components";
import ImageGallery from "../ImageGallery";
import { TextButton } from "@yamaha-admin-sb/text-button";
import { Loader } from "../Loader";
const StyledImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 24px 0;
`;

const StyledImagePreview = styled.div`
  padding: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 94px;
  height: 94px;
  border-radius: 12px;
  img {
    display: block;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;

    transform: translate(-50%, -50%);
  }
  border: 2px solid var(--color-white);
  &:hover {
    border: 2px solid var(--color-blue-200);
  }
`;

export const Images: React.FC<{
  isLoading: boolean;
  images: Pick<AttachmentOutput, "reportId" | "createdAt" | "fileName" | "filePath" | "fileSize" | "fileUrl" | "id">[];
}> = ({ isLoading, images }) => {
  const [showAll, setShowAll] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [startIndex, setStartIndex] = useState(0);

  const WrappedLoader = styled.div`
    margin: 16px 0;
  `;

  console.log("images", images);

  return (
    <>
      {!isLoading ? (
        images?.length ? (
          <>
            <StyledImages>
              {images?.map((item, index) =>
                (index <= 5 && !showAll) || showAll ? (
                  <StyledImagePreview
                    key={item.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPreview(true);
                      setStartIndex(index);
                    }}
                  >
                    <img src={item.fileUrl || ""} />
                  </StyledImagePreview>
                ) : (
                  <></>
                ),
              )}
            </StyledImages>
            {images?.length > 5 ? (
              <TextButton
                rightIcon={true}
                leftIcon={false}
                icon={showAll ? "chevron-up" : "chevron-down"}
                onClick={() => setShowAll((prev) => !prev)}
              >
                {showAll ? "Скрыть" : "Показать еще"}
              </TextButton>
            ) : (
              <></>
            )}
            {showPreview ? (
              <ImageGallery gallery={images} startIndex={startIndex} onClose={() => setShowPreview(false)} />
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )
      ) : (
        <WrappedLoader>
          <Loader size="m" />
        </WrappedLoader>
      )}
    </>
  );
};
