import React, { useEffect, useState } from "react";
import {
  GearboxEnum,
  TechnicsData,
  useGetVehicleMarksQuery,
  useGetVehicleModelsQuery,
} from "@/graphql/generated";
import { Title } from "@yamaha-admin-sb/title";
import { StyledGridContainer, StyledGridElement } from "./leads.styled";

import { Controller } from "@/components/Controller";
import { IInput, Input } from "@yamaha-admin-sb/input";
import { Control, UseFormSetValue } from "react-hook-form";
import {
  BODY_OPTIONS,
  DRIVE_UNIT_OPTIONS,
  inputValidationRule,
  requiredValidationRule,
} from "@/helpers/const";
import { Textarea, ITextarea } from "@yamaha-admin-sb/textarea";
import { TextButton } from "@yamaha-admin-sb/text-button";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { IconButton } from "@yamaha-admin-sb/icon-button";

import { Dropdown, IDropdown, IDropdownItem } from "@yamaha-admin-sb/dropdown";
import { toFormatPrice } from "@/helpers/utils";

export interface ITechnicsDataTemplate extends TechnicsData {
  id?: number;
}

export const gearboxOptions: IDropdownItem[] = [
  {
    name: "Механическая КПП",
    value: GearboxEnum.Manual,
  },
  {
    name: "Автоматическая КПП",
    value: GearboxEnum.Automatic,
  },
  {
    name: "Роботизированная КПП",
    value: GearboxEnum.Robotic,
  },
  {
    name: "Вариативная КПП",
    value: GearboxEnum.Cvt,
  },
];

interface ITechnicsForm {
  technicsData?: ITechnicsDataTemplate[];
  control: any;
  errors: { [key: string]: any };
  setValue: UseFormSetValue<any>;
  onUpdate: (data: ITechnicsDataTemplate[]) => void;
}

interface IFromTemplate {
  data: ITechnicsDataTemplate;
  index: number;
  control: Control<any>;
  errors: { [key: string]: any };
  setValue: (name: string, value: unknown, config?: Object) => void;
  onCarDeleteClick: () => void;
  handelChange: (key: keyof ITechnicsDataTemplate, value: any) => void;
}

const DEFAULT_TECHNICS_DATA: ITechnicsDataTemplate = {
  parameters: "",
  make: "",
  color: null,
  issueYear: null,
  maxPrice: null,
  minPrice: null,
  model: null,
  body: null,
  gearbox: null,
  driveUnit: null,
  mileage: null,
  equipmentOptions: null,
  engineVolume: null,
};

// export const driveUnitOptions: IDropdownItem[] = [
//   {
//     name: "Передний привод",
//     value: DriveUnitEnum.FrontWheel,
//   },
//   {
//     name: "Задний привод",
//     value: DriveUnitEnum.RearWheel,
//   },
// ];

const FormTemplate: React.FC<IFromTemplate> = (props) => {
  const {
    data,
    index,
    setValue,
    control,
    errors,
    onCarDeleteClick,
    handelChange,
  } = props;

  const [selectedMarks, setSelectedMarks] = useState<IDropdownItem[]>([]);
  const [selectedModels, setSelectedModels] = useState<IDropdownItem[]>([]);
  const [selectedFilters, setSelectedFilters] = useState({
    model: {
      name: "",
      id: 0,
    },
    mark: {
      name: "",
    },
  });

  useEffect(() => {
    Object.keys(data).forEach((item) =>
      setValue(`${item}-${index}`, data[item])
    );
  }, [data]);

  const { data: models } = useGetVehicleModelsQuery(
    {
      filter: selectedMarks[0]?.id
        ? selectedFilters?.model?.name
          ? {
              markId: selectedMarks[0]?.id.toString(),
              name: selectedFilters.model.name,
            }
          : { markId: selectedMarks[0]?.id.toString() }
        : {},
    },
    {
      staleTime: 5 * 60 * 1000,
      enabled: !!selectedMarks[0]?.id,
    }
  );
  const { data: marks, isLoading: isMarksLoading } = useGetVehicleMarksQuery({
    filter: selectedFilters?.mark?.name
      ? {
          name: selectedFilters.mark.name,
        }
      : {},
  });

  useEffect(() => {
    const prevMarks = marks?.getVehicleMarks.find(
      (item) => item.name === data.make
    );
    prevMarks && setSelectedMarks([prevMarks]);
  }, [marks, data]);

  useEffect(() => {
    const prevModels = models?.getVehicleModels.filter(
      (item) => !!data.model?.split("; ").includes(item.name)
    );
    prevModels && setSelectedModels(prevModels);
  }, [models, marks, selectedMarks]);

  return (
    <StyledGridContainer>
      <StyledGridElement
        className="display-flex jc-sb ai-c"
        column="1 / span 2"
      >
        <Paragraph size={12} color="gray-600">
          Автомобиль №{index + 1}
        </Paragraph>
        {index ? (
          <IconButton
            color="gray"
            variant="tertiary"
            icon="trash"
            onClick={onCarDeleteClick}
          />
        ) : (
          <></>
        )}
      </StyledGridElement>
      <StyledGridElement column="1">
        <Controller<IDropdown>
          // portal={document.querySelector("body") as HTMLElement}
          name={`make-${index}`}
          label="Марка *"
          searchable={true}
          onSearch={(value) =>
            setSelectedFilters((prev) => ({ ...prev, mark: { name: value } }))
          }
          placeholder="Выберите марку"
          control={control}
          error={errors[`make-${index}`]?.message}
          options={marks?.getVehicleMarks || []}
          isDisabled={isMarksLoading}
          isBordered={true}
          value={selectedMarks}
          rules={requiredValidationRule}
          onChange={(value: IDropdownItem[]) => {
            setSelectedMarks(value);
            value[0]?.name && handelChange("make", value[0].name);
          }}
          component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Controller<IDropdown>
          // portal={document.querySelector("body") as HTMLElement}
          name={`model-${index}`}
          label="Модель *"
          rules={requiredValidationRule}
          error={errors[`model-${index}`]?.message}
          placeholder="Выберите модель"
          control={control}
          searchable={true}
          options={models?.getVehicleModels || []}
          isBordered={true}
          value={selectedModels}
          isMultiple={true}
          isDisabled={!selectedMarks[0]?.id || isMarksLoading}
          withTags={true}
          onSearch={(value) =>
            setSelectedFilters((prev) => ({
              ...prev,
              model: { ...prev.model, name: value },
            }))
          }
          isVerticalTags={true}
          onChange={(value: IDropdownItem[]) => {
            setSelectedModels(value);
            handelChange(
              "model",
              value?.map((item) => item.name).join("; ") || []
            );
          }}
          component={(inputProps: IDropdown) => <Dropdown {...inputProps} />}
        />
      </StyledGridElement>
      <StyledGridElement column="1">
        <Dropdown
          label="Коробка передач"
          placeholder="Выберите коробку передач"
          isMultiple={true}
          withTags={true}
          isBordered={true}
          value={[
            gearboxOptions.filter((item) =>
              data.gearbox?.includes(item?.value)
            ) || [],
          ].flat()}
          options={gearboxOptions}
          onChange={(value) =>
            handelChange("gearbox", value?.map((item) => item?.value) || [])
          }
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Dropdown
          label="Привод"
          placeholder="Выберите привод"
          isBordered={true}
          isMultiple={true}
          withTags={true}
          value={[
            DRIVE_UNIT_OPTIONS.filter((item) =>
              data.driveUnit?.includes(item?.name)
            ) || [],
          ].flat()}
          options={DRIVE_UNIT_OPTIONS}
          onChange={(value) =>
            handelChange("driveUnit", value?.map((item) => item?.name) || [])
          }
        />
      </StyledGridElement>
      <StyledGridElement column="1">
        <Input
          name={`issueYear-${index}`}
          label="Год выпуска"
          placeholder="Выберите год выпуска"
          value={data.issueYear || ""}
          type="number"
          isBordered={true}
          onChange={(value) => handelChange("issueYear", +value)}
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Input
          name={`color-${index}`}
          label="Цвет"
          placeholder="Введите цвет"
          value={data.color || ""}
          isBordered={true}
          onChange={(value) => handelChange("color", value)}
        />
      </StyledGridElement>
      <StyledGridElement column="1">
        <Input
          name={`engineVolume-${index}`}
          label="Объем двигателя"
          placeholder="Введите объем двигателя"
          suffix="л"
          type="number"
          value={data.engineVolume || ""}
          isBordered={true}
          onChange={(value) => {
            handelChange("engineVolume", +value);
          }}
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Dropdown
          label="Kyзов"
          placeholder="Выберите  тип кузова"
          isBordered={true}
          isMultiple={true}
          withTags={true}
          value={[
            BODY_OPTIONS.filter((item) => data.body?.includes(item?.name)) ||
              [],
          ].flat()}
          options={BODY_OPTIONS}
          onChange={(value) =>
            handelChange(
              "body",
              value?.map((item) => item?.name)
            )
          }
        />
      </StyledGridElement>
      <StyledGridElement column="1">
        <Input
          name={`minPrice-${index}`}
          label="Цена"
          placeholder="От"
          suffix="₽"
          value={
            data?.minPrice && !isNaN(+data?.minPrice)
              ? toFormatPrice(+data.minPrice)
              : ""
          }
          isBordered={true}
          onChange={(value) => {
            const digits = value.split(/\D/).join("");
            digits && handelChange("minPrice", +digits);
          }}
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Input
          name={`maxPrice-${index}`}
          label="Цена"
          placeholder="До"
          suffix="₽"
          value={
            data?.maxPrice && !isNaN(+data?.maxPrice)
              ? toFormatPrice(+data.maxPrice)
              : ""
          }
          isBordered={true}
          rules={inputValidationRule}
          onChange={(value) => {
            const digits = value.split(/\D/).join("");
            digits && handelChange("maxPrice", +digits);
          }}
        />
      </StyledGridElement>
      <StyledGridElement column="1">
        <Input
          name={`mileage-${index}`}
          label="Пробег"
          placeholder="До"
          suffix="км"
          value={
            data?.mileage && !isNaN(+data?.mileage)
              ? toFormatPrice(+data.mileage)
              : ""
          }
          isBordered={true}
          rules={inputValidationRule}
          onChange={(value) => {
            const digits = value.split(/\D/).join("");
            digits && handelChange("mileage", +digits);
          }}
        />
      </StyledGridElement>
      <StyledGridElement column="2">
        <Controller<IInput>
          name={`parameters-${index}`}
          label="Ссылка на авто"
          placeholder="Введите сслыку на авто"
          value={data?.parameters || ""}
          isBordered={true}
          // rules={inputValidationRule}
          onChange={(value) => handelChange("parameters", value)}
          control={control}
          // error={errors[`parameters-${index}`]?.message}
          component={(inputProps: IInput) => <Input {...inputProps} />}
        />
      </StyledGridElement>
      <StyledGridElement column="1 / span 2">
        <Controller<ITextarea>
          name={`equipmentOptions-${index}`}
          label="Комплектация. Необходимые опции"
          placeholder="Введите текст"
          control={control}
          // error={errors[`equipmentOptions-${index}`]?.message}
          value={data?.equipmentOptions || ""}
          isBordered={true}
          // rules={inputValidationRule}
          onChange={(value) => handelChange("equipmentOptions", value)}
          component={(inputProps: ITextarea) => <Textarea {...inputProps} />}
        />
      </StyledGridElement>
    </StyledGridContainer>
  );
};
export const TechnicsForm: React.FC<ITechnicsForm> = (props) => {
  const { technicsData, control, setValue, errors, onUpdate } = props;

  const [technics, setTechnics] = useState<ITechnicsDataTemplate[]>(
    technicsData && technicsData[0] ? technicsData : [DEFAULT_TECHNICS_DATA]
  );

  useEffect(() => {
    onUpdate(technics);
  }, [technics]);

  const handleChange = (
    key: keyof ITechnicsDataTemplate,
    value: any,
    carIndex: number
  ) => {
    setTechnics((prev) =>
      prev.map((item, index) =>
        carIndex === index ? { ...item, [key]: value } : item
      )
    );
  };

  return (
    <>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Детали автоподбора
        </Title>
      </div>

      {
        technics
          .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
          .map((item, index) => (
        <FormTemplate
          key={index}
          data={
            technicsData && technicsData[index]
              ? { ...technicsData[index], ...item }
              : item
          }
          index={index}
          control={control}
          errors={errors}
          setValue={setValue}
          onCarDeleteClick={() =>
            setTechnics((prev) =>
              prev.filter((_, carIndex) => carIndex !== index)
            )
          }
          handelChange={(key, value) => handleChange(key, value, index)}
        />
      ))}
      {technics.length < 5 ? (
        <div className="space-20">
          <TextButton
            leftIcon={true}
            icon="plus"
            onClick={() =>
              setTechnics((prev) => [...prev, DEFAULT_TECHNICS_DATA])
            }
          >
            Добавить еще автомобиль
          </TextButton>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
