import { colors, media } from "@/helpers/index";
import { mediaRevers } from "@/helpers/styleBreakpoints";
import styled from "styled-components";

const WrappedLeadTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledNameItem = styled.span`
  min-width: 84px;
  max-width: 84px;
  margin-right: 8px;
`;
const StyledValueItem = styled.span`
  max-width: 187px;
`;

const StyledInfoItem = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const WrappedBlock = styled.div`
  padding: 24px 40px 24px 0;
  border-bottom: 1px solid ${colors.gray200};
`;

const StyledGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${media.desktop} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  gap: 16px 20px;
`;

const StyledGridElement = styled.div<{ column: string }>`
  grid-column: ${({ column }) => column};
`;

const StyledFormItem = styled.div`
  padding: 32px 16px 40px;

  ${media.tablet} {
    padding: 32px 32px 40px;
  }
  ${media.laptop} {
    padding: 32px 40px 40px;
  }
  border-bottom: 1px solid ${colors.gray200};
`;

const StyledDocumentsList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

const StyledLaptopReverseHidden = styled.div`
  ${mediaRevers.laptop} {
    display: none;
  }
`;
const StyledLaptopHidden = styled.div`
  ${media.laptop} {
    display: none;
  }
`;

export {
  WrappedLeadTitle,
  StyledNameItem,
  StyledValueItem,
  StyledInfoItem,
  WrappedBlock,
  StyledGridContainer,
  StyledFormItem,
  StyledGridElement,
  StyledDocumentsList,
  StyledLaptopReverseHidden,
  StyledLaptopHidden,
};
