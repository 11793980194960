import { useQueryParams } from "@/hooks/useQueryParams";
// import { Redirect } from "@reach/router";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Main: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const search = useQueryParams();

  useEffect(() => {
    if (search.leadPublicId) {
      navigate(`/${search.leadPublicId}?paymentStauts=${search.status}`);
    }
  }, [search]);

  return <></>;
};

export default Main;
