import styled from "styled-components";

export const StyledFormGrid = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  gap: 20px;
`;

export const StyledFormGridItem = styled.div`
  position: relative;
  width: 100%;
  max-width: calc(50% - 10px);
`;
