import { colors } from "@/helpers/index";
import { Icon } from "@yamaha-admin-sb/icon";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import React from "react";
import styled from "styled-components";
interface IDataFilled {
  text: string;
  color?: string;
}

const StyledDataFilled = styled.div<{ color: string }>`
  padding: 8px;
  border-radius: 4px;
  background-color: ${({ color }) => `var(--color-${color})`};
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
`;

export const DataFilled: React.FC<IDataFilled> = (props) => {
  const { text, color = "green-200" } = props;
  return (
    <StyledDataFilled color={color}>
      <Icon color="neutral-white" name="check" size={16} />
      <Paragraph size={12} color="neutral-white">
        {text}
      </Paragraph>
    </StyledDataFilled>
  );
};
