import React, { useEffect, useMemo, useState } from "react";
import { DocumentPreview } from "@/components/DocumentPreview";
import { downloadURL } from "@/helpers/utils";
import { Title } from "@yamaha-admin-sb/title";
import { useParams } from "react-router-dom";
import { StyledFormItem } from "../leads.styled";
import { Images } from "../Images";
import {
  LeadReportOutput,
  Maybe,
  useGetAttachmentsForPublicQuery,
} from "@/graphql/generated";
import styled from "styled-components";
import Cookies from "js-cookie";
import { Paragraph } from "@yamaha-admin-sb/paragraph";
import { getDocument } from "@/hooks/getDocument";

interface IReportProps {
  reports?: Maybe<
    Pick<
      LeadReportOutput,
      "createdAt" | "id" | "fileName" | "fileSize" | "fileUrl" | "filePath"
    >[]
  >;
}

const StyledReportPreview = styled.div``;
const WrappedDocument = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;


const fetchFile = async (key: string) => {
  return await getDocument({ key });
};


export const Report: React.FC<IReportProps> = (props) => {
  const { reports } = props;
  const { uuid = "" } = useParams();
  const [photoPublicLinks, setPhotoPublicLinks] = useState<{} | undefined>(undefined);

  const { data: images, isLoading } = useGetAttachmentsForPublicQuery({
    input: {
      access: Cookies.get("access") || "",
      path: uuid,
    },
  });

  useEffect(() => {
    const processImages = async () => {
      const getPublicLinks = await images?.getAttachmentsForPublic.reduce(async (accPromise, item) => {
        const acc = await accPromise;
        acc[item.id] = item.filePath ? await getDocument({ key: item.filePath }) : item.fileUrl;
        return acc;
      }, Promise.resolve({}));
      console.log("getPublicLinks", getPublicLinks);
      setPhotoPublicLinks(getPublicLinks);
    };

    processImages();
  }, [images]);



  const handleDownloadClick = async (id: number) => {
    const link = reports && reports.find((rep) => rep.id === id)?.filePath;
    if (!link) return;
    const fileUrl = await fetchFile(link);
    const filename = link.split("/").pop() as string;
    if (!fileUrl) return;
    await downloadURL(fileUrl, filename);
  };

  const handleViewClick = async (id: number) => {
    const link = reports && reports.find((rep) => rep.id === id)?.filePath;
    if (!link) return;
    const fileUrl = await fetchFile(link);
    window.open(fileUrl, "_blank");
  };

  /*  const preparedImagesList = useMemo(
     () =>
       images?.getAttachmentsForPublic.reduce(
         (acc, item) => ({
           ...acc,
           [item.reportId]: acc[item.reportId]
             ? [...acc[item.reportId], item]
             : [item],
         }),
         {}
       ),
     [images]
   ); */

  const preparedImagesList = useMemo(
    () =>
      images?.getAttachmentsForPublic.reduce(
        (acc, item) => ({
          ...acc,
          [item.reportId]: acc[item.reportId] ?
            [...acc[item.reportId], {
              ...item,
              fileUrl: item.fileUrl ? photoPublicLinks?.[item.id] : item.fileUrl,
            }] :
            [{
              ...item,
              fileUrl: item.fileUrl ? photoPublicLinks?.[item.id] : item.fileUrl,
            }],
        }),
        {}
      ),
    [photoPublicLinks]
  );
  console.log(preparedImagesList);
  const getDateFormat = (rawDate) => {
    const date = new Date(rawDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}.${formattedMonth}.${year}`;
  };

  return (
    <StyledFormItem>
      <div className="space-24">
        <Title level={4} weight={500} color="gray-700">
          Отчет специалиста
        </Title>
      </div>
      {reports?.length ? (
        <StyledReportPreview>
          {reports?.map((item) => (
            <WrappedDocument>
              <DocumentPreview
                key={item.id}
                name={item.fileName || "Отчет"}
                size={item.fileSize}
                onDownload={() =>
                  item.id && handleDownloadClick(item.id)
                }
                onView={() => item.id && handleViewClick(item.id)}
              />
              {item.createdAt && (
                <Paragraph size={12} color="gray-600">
                  От {getDateFormat(item.createdAt)}
                </Paragraph>
              )}
              <Images
                isLoading={isLoading}
                images={preparedImagesList ? preparedImagesList[item.id] : []}
              />
            </WrappedDocument>
          ))}
        </StyledReportPreview>
      ) : (
        <></>
      )}
    </StyledFormItem>
  );
};
