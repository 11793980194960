import React, { Dispatch, SetStateAction } from "react";

export interface IModalContextValue {
  payload: any;
  modalContractConfirm: boolean;
}

type TModalContext =
  | [
      userValue: IModalContextValue,
      setUserValue: Dispatch<SetStateAction<IModalContextValue>>,
    ]
  | [];

export const ModalContext = React.createContext<TModalContext>([]);
